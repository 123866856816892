import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/Dashboard';
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import HomeIcon from "@mui/icons-material/Home";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Paper, Tooltip } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useAuth from "../../Context/useAuth";
import "./style.css";

function Navbar() {
  const [activeNav, setActiveNav] = React.useState("home");
  const { user, adminData, userSignOut, noti } = useAuth();
  const navigate = useNavigate();
  const handleLogOut = () => {
    const proced = window.confirm('Do you want to Logout ?');
    if (proced) {
      userSignOut();
      navigate('/');
    }
  }

  return (
    <div className="navbar">
      <div className="upperBar">
        {user?.email ? (
          <NavLink to="/profile/my-profile">
            <Tooltip title="Profile" arrow>
              <Paper
                style={{
                  backgroundColor: activeNav === "profile" && "#8f9d9f",
                  color: activeNav === "profile" && "white",
                }}
                className="navbtn"
              >
                <AccountCircleIcon />
              </Paper>
            </Tooltip>
          </NavLink>
        ) : (
          <div style={{ margin: "15px 0px" }} className="img">
            <Avatar style={{ width: '40px', height: '40px' }} />
          </div>
        )}

        <NavLink to="/" onClick={() => setActiveNav("home")}>
          <Tooltip title="Home" arrow>
            <Paper
              style={{
                backgroundColor: activeNav === "home" && "#8f9d9f",
                color: activeNav === "home" && "white",
              }}
              className="navbtn"
            >
              <HomeIcon />
            </Paper>
          </Tooltip>
        </NavLink>
        {(user?.email && !adminData) && <NavLink to="/favourite" onClick={() => setActiveNav("favourite")}>
          <Tooltip title="Favourite" arrow>
            <Paper
              style={{
                backgroundColor: activeNav === "favourite" && "#8f9d9f",
                color: activeNav === "favourite" && "white",
              }}
              className="navbtn"
            >
              <FavoriteBorderIcon />
            </Paper>
          </Tooltip>
        </NavLink>}

        {(user?.email && !adminData) && <NavLink
          to="/notification"
          onClick={() => setActiveNav("notification")}
        >
          <Tooltip title="Notification" arrow>
            <Paper
              style={{
                backgroundColor: activeNav === "notification" && "#8f9d9f",
                color: activeNav === "notification" && "white",
              }}
              className="navbtn"
            >
              <Badge badgeContent={noti?.length} color="secondary">
                <NotificationsNoneIcon />
              </Badge>
            </Paper>
          </Tooltip>
        </NavLink>}
        {(user?.email && adminData) && <NavLink
          to="/dashboard"
          onClick={() => setActiveNav("admin")}
        >
          <Tooltip title="Dashboard" arrow>
            <Paper
              style={{
                backgroundColor: activeNav === "admin" && "#8f9d9f",
                color: activeNav === "admin" && "white",
              }}
              className="navbtn"
            >
              <AccountBoxIcon />
            </Paper>
          </Tooltip>
        </NavLink>}
        {!user?.email ?
          <NavLink to="/signin" style={{ textDecoration: "none" }} onClick={() => setActiveNav("signin")}>
            <Tooltip title="Log In" arrow>
              <Paper
                style={{
                  backgroundColor: activeNav === "signin" && "#8f9d9f",
                  color: activeNav === "signin" && "white",
                  cursor: "pointer"
                }}
                className="navbtn"
              >
                {/* <LoginIcon /> */}
                Login
              </Paper>
            </Tooltip>

          </NavLink>
          :
          <Tooltip title="Log Out" arrow>
            <Paper
              style={{
                backgroundColor: "#8f9d9f",
                color: "white",
                cursor: "pointer"
              }}
              className="navbtn"
              onClick={handleLogOut}
            >
              {/* <LogoutIcon /> */}
              Log Out
            </Paper>
          </Tooltip>

        }
      </div>
      {/* <div className="logout">

      </div> */}
    </div>
  );
}

export default Navbar;
