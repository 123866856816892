import DeleteIcon from '@mui/icons-material/Delete';
import {
	Paper, Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { DashboardLayout } from "../layout/dashboard-layout";
import "./form.css";

const IngredientsForms = () => {
	const { register, handleSubmit, reset } = useForm();
	const [ingredients, setIngredients] = useState([]);
	useEffect(() => {
		axios
			.get(
				"https://admin.sliceguys.co.uk/ingredients"
			)
			.then((res) => {
				setIngredients(res.data);
			});
	}, []);
	const onSubmit = (data) => {
		if (data.ToopingName === '') {
			swal({
				text: "Please Insert Ingredient Name",
				icon: "warning",
			});
		} else if (isNaN(parseFloat(data.toopingPrice))) {
			swal({
				text: "Please Insert Ingredient Price",
				icon: "warning",
			});
		} else {
			axios
				.post("https://admin.sliceguys.co.uk/ingredients", data)
				.then((res) => {
					if (res.data.insertedId) {
						alert("Ingredient added Successfully.");
						let newIng = {
							_id: res.data.insertedId,
							ToopingName: data.ToopingName,
							category: data.category,
							toopingPrice: data.toopingPrice,
						}
						let ing = ingredients;
						ing.push(newIng)
						setIngredients(ing);
						reset();
					}
				}).catch((e) => {
					console.log(e);
				});
		}

	};
	const deleteIngredient = (id) => {
		const proceed = window.confirm("Do you want to delete this ingredient?");
		if (proceed) {
			const uri = `https://admin.sliceguys.co.uk/ingredients/${id}`;
			fetch(uri, {
				method: "DELETE",
			})
				.then((res) => res.json())
				.then((data) => {
					if (data.deletedCount > 0) {
						alert("Ingredient Deleted Successfully");
						const remainingIngredients = ingredients.filter(
							(data) => data._id !== id
						);
						setIngredients(remainingIngredients);
					}
				}).catch((e) => {
					alert("Ingredient Deletion Failed!");
				});
		}
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<Box className="formContainer">
					<Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
						Ingredients
					</Typography>
					<Paper elevation={3} sx={{ p: 1 }}>
						<TableContainer sx={{ backgroundColor: "#E5E5E5", color: "white" }}>
							<Table aria-label="spanning table">
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: "#F2D7D5" }}>Name</TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}>Category</TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}>Price</TableCell>
										<TableCell
											align="center"
											sx={{ color: "#F2D7D5" }}
										>Remove</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{ingredients ? (
										ingredients?.map((ing) => (
											<TableRow key={ing._id} ing={ing}>
												<TableCell>{ing.ToopingName}</TableCell>
												<TableCell>{ing.category}</TableCell>
												<TableCell>&pound; {ing.toopingPrice}</TableCell>
												<TableCell align="center">
													<IconButton edge="end" aria-label="delete" onClick={() => deleteIngredient(ing._id)}>
														<DeleteIcon />
													</IconButton>
												</TableCell>
											</TableRow>
										))
									) : (
										<TableCell>loadding...</TableCell>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</Box>
			</Grid>
			<Grid item xs={12} md={6}>
				<Box className="formContainer">
					<h3>Add Ingredient</h3>
					<Paper className="formsection">
						<form onSubmit={handleSubmit(onSubmit)}>
							<Box className="smallInputContainer">
								<Box className="smallInput">
									<label>Ingredients Name</label>
									<input {...register("ToopingName")} />
								</Box>
							</Box>
							<Box className="smallInputContainer">
								<Box className="smallInput">
									<label>Ingredients Category</label>
									<select {...register("category")}>
										<option key="1" value="Pizza">
											Pizza Topping
										</option>
										<option key="2" value="Shakes">
											Shakes
										</option>
										<option key="3" value="Dips">
											Dips
										</option>
									</select>
								</Box>
							</Box>
							<Box className="smallInputContainer">
								<Box className="smallInput">
									<label>Ingredients price</label>
									<input type="number" step="0.01" {...register("toopingPrice")} />
								</Box>
							</Box>

							<Box style={{ display: "flex", paddingLeft: "5px" }}>
								<input type="submit" className="frombutton" />
								<input type="reset" className="frombutton" />
							</Box>
						</form>
					</Paper>
				</Box>
			</Grid>
		</Grid>

	);
};
IngredientsForms.getLayout = (page) => (
	<DashboardLayout>{page}</DashboardLayout>
);
export default IngredientsForms;
