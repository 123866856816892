import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid } from "@mui/material";
import { useState } from "react";
import { ImCross } from "react-icons/im";
import useAuth from "../../Context/useAuth";

const CartItem = ({ item, index }) => {
  // console.log(item.toopingNames)
  const { increaseItem, decreaseItem, removeCart } = useAuth();
  const [toggle, setToggle] = useState(true);
  const InggedientAvailableCategories = ["Pizza", "Wings", "Vegan", "Sides", "Shakes", "Deals"];

  return (
    <div>
      <div className="cartItem">
        <Grid container>
          <Grid item xs={1} className="cartItemQuentity">
            <KeyboardArrowDownIcon
              onClick={() => increaseItem(index)}
              style={{
                transform: "rotate(180deg)",
              }}
            />
            <span>{item?.quantity}</span>
            <KeyboardArrowDownIcon
              onClick={() => decreaseItem(index)}
            />
          </Grid>

          <Grid item xs={3} className="cartItemImage">
            <img src={`data:image/*;base64, ${item?.file}`} alt="" />
          </Grid>

          <Grid item xs={7} className="details" style={{ padding: "0px" }}>
            <h5>{item?.title}</h5>
            <small>£ {parseFloat(item?.totalQuantityPrice).toFixed(2)}</small>
          </Grid>
          <Grid item xs={1} className="toggle">
            <ImCross
              onClick={() => removeCart(index)}
              style={{ width: "10px" }}
            />
            <KeyboardArrowDownIcon
              onClick={() => setToggle(!toggle)}
              style={{
                transform: toggle ? "rotate(360deg)" : "rotate(180deg)",
                display: InggedientAvailableCategories.includes(item?.category) ? 'block' : 'none'
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div className="extraAdons" style={{ display: toggle && "none" }}>
        {
          item?.toopingNames ? <span>{item.toopingNames.slice(0, item.toopingNames.length - 2)}</span>
            : <span>No toppings added</span>
        }
      </div>
    </div>
  );
};

export default CartItem;
