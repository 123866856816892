import { Box, Container } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import io from 'socket.io-client';
import swal from "sweetalert";
import useSound from 'use-sound';
import notificationSound from "../assets/sounds/order_notification.mp3";
import { CustomerListResults } from "../components/customer/customer-list-results";
import { CustomerListToolbar } from "../components/customer/customer-list-toolbar";
import { DashboardLayout } from "../components/layout/dashboard-layout";
import useAuth from "../Context/useAuth";
import "./order-list.css";

const socket = io.connect("https://admin.sliceguys.co.uk");

const OrderList = () => {
	const [alldata, setAlldata] = useState([]);
	const [newOrderId, setNewOrderId] = useState(null);
	const [singleOrder, setSingleOrder] = useState(null);
	const [orderItems, setOrderItems] = useState(null);
	const [playSound, setPlaySound] = useState(false);
	const { status, setStatus } = useAuth();
	const [intervalId, setIntervalId] = useState(null);
	const navigate = useNavigate();

	const [play] = useSound(notificationSound, { interrupt: true });


	const handleUpdate = (value, id) => {
		setPlaySound(false)
		const statusChange = {
			status: value,
		};
		const url = `https://admin.sliceguys.co.uk/update-sell-products/${id}`;
		axios.put(url, statusChange).then((res) => {
			if (res.data.modifiedCount > 0) {
				setStatus(!status);
				socket.emit("order_update", { order_id: id });
			}
		});
	};

	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/buy-product")
			.then((res) => res.json())
			.then((data) => {
				setAlldata(data);
			});
	}, [status, newOrderId]);


	useEffect(() => {
		socket.on("order_received", (order_data) => {
			setNewOrderId(order_data.data.order_id);
		})
	}, [socket]);

	useEffect(() => {
		if (newOrderId === null) return false;
		let url = `https://admin.sliceguys.co.uk/buy-product/${newOrderId}`;
		fetch(url)
			.then((res) => res.json())
			.then((data) => {
				setSingleOrder(data)
			});
	}, [newOrderId]);


	useEffect(() => {
		if (singleOrder === null) return false;
		let products = "";
		singleOrder.items.forEach((e) => {
			products = products + e.title + "(" + e.quantity + "), ";
		})
		setOrderItems(products.slice(0, products.length - 2));
	}, [singleOrder])



	useEffect(() => {
		if (newOrderId === null || orderItems === null) return false;
		// play();
		setPlaySound(true);
		swal({
			title: "New order has been placed",
			icon: "success",
			text: "product(s): " + orderItems,
			buttons: {
				// skip: "Skip",
				details: { text: 'Details', className: 'info-btn' },
				cancelOrder: { text: "Cancel", className: "cancelOrder-btn" },
				approve: "Approve"
			},
			confirmButtonColor: "black"
		}).then((value) => {
			switch (value) {
				// case "skip":
				// 	setPlaySound(false);
				// 	break;

				case "details":
					setPlaySound(false)
					navigate("/allorders/" + newOrderId);
					break;

				case "cancelOrder":
					setPlaySound(false)
					handleUpdate("Canceled Order", newOrderId)
					break;

				case "approve":
					setPlaySound(false)
					handleUpdate("Approved Order", newOrderId)
					break;

				default:
					setPlaySound(false)
					break;
			}
		});
	}, [orderItems])

	useEffect(() => {
		if (playSound) {
			let intId = setInterval(function () {
				play();
			}, 2000);
			setIntervalId(intId);
		} else {
			if (intervalId) {
				clearInterval(intervalId);
			}
		}
	}, [playSound]);


	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				py: 3,
			}}
		>
			<Container maxWidth={false}>
				<CustomerListToolbar />
				<Box sx={{ py: 2 }}>
					<CustomerListResults
						alldata={alldata}
						handleUpdate={handleUpdate}
					/>
				</Box>
			</Container>
		</Box>
	);
};
OrderList.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default OrderList;
