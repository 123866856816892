import FolderIcon from "@mui/icons-material/Folder";
import { Avatar, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { DashboardLayout } from "../layout/dashboard-layout";

const EditCategores = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const inputRef = useRef(HTMLInputElement);

	const [category, setcategory] = useState({});
	const [myfiles, setMyFiles] = useState(null);

	const getImagePreview = (e) => {
		let image = URL.createObjectURL(e.target.files[0]);
		let imagediv = document.getElementById("preview");
		imagediv.src = image;
		setMyFiles(e.target.files[0]);
	};

	useEffect(() => {
		const url = `https://admin.sliceguys.co.uk/allcategori/${id}`;
		fetch(url)
			.then((res) => res.json())
			.then((data) => setcategory(data));
	}, [id]);

	const { register, handleSubmit } = useForm();

	const onSubmit = async (data) => {
		let upData = {};
		if (myfiles) {
			const fileData = await convertBase64(myfiles);
			const finalFile = await fileData.split('data:image/jpeg;base64,')[1]

			upData = {
				categoriName: data.categoriName,
				file: finalFile
			}
		} else {
			upData = {
				categoriName: data.categoriName
			}
		}

		axios
			.put(
				`https://admin.sliceguys.co.uk/allcategori/${id}`,
				upData
			)
			.then((res) => {
				if (res.data) {
					swal({
						text: "Category Update Successful!",
						icon: "success",
						buttons: false,
						timer: 2000,
					});
					navigate("/categories");
				}
			}).catch((e) => {
				console.log(e);
			});
	};

	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};

	return (
		<Box className="formContainer">
			{category?._id && (
				<Paper className="formsection">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box
							style={{
								display: "flex",
								alignItems: "center",
								margin: "10px 0px",
							}}
						>
							<Box className="smallInput">
								<img
									src={`data:image/*;base64, ${category.file}`}
									alt=""
									id="preview"
									style={{
										height: "56px",
										width: "56px",
										borderRadius: "100%",
										objectFit: "cover",
									}}
								/>
							</Box>
							<Box className="smallInput" sx={{ mx: "30px" }}>
								<input
									type="file"
									accept="image/*"
									onChange={getImagePreview}
									style={{ display: "none" }}
									ref={inputRef}
								/>
								<Avatar onClick={() => inputRef.current.click()}>
									<FolderIcon />
								</Avatar>
							</Box>
						</Box>
						<Box className="smallInputContainer">
							<Box className="smallInput">
								<label>Title</label>
								<input
									type="text"
									defaultValue={category.categoriName}
									{...register("categoriName")}
								/>
							</Box>
						</Box>

						<Box style={{ display: "flex", paddingLeft: "5px" }}>
							<input type="submit" value="Update" className="frombutton" />
							<input type="reset" Value="Cancel" className="frombutton" />
						</Box>
					</form>
				</Paper>
			)}
		</Box>
	);
};

EditCategores.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default EditCategores;
