import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from "axios";
import { useState } from "react";
import swal from "sweetalert";
// import useAuth from '../../Context/useAuth';



export default function ForgetPassword() {
  // const { handleForgetPassword } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  const [sendingEmail, setSendingEmail] = useState(false);
  const [submittingOtp, setSubmittingOtp] = useState(false);


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleSubmit = () => {
    handleForgetPassword(email);
  }
  const handlePasswordSubmit = () => {
    if (newPassword !== confirmNewPassword) {
      swal({
        text: "Please Confirm Correct Password!",
        icon: "warning",
        buttons: false,
        timer: 2000,
      });
      return false;
    }
    if (otp.length !== 4) {
      swal({
        text: "Please Insert Valid OTP!",
        icon: "warning",
        buttons: false,
        timer: 2000,
      });
      return false;
    }
    handleChangePassword(email, otp, newPassword);
  }
  const handleForgetPassword = (email) => {
    if (email === '') {
      swal({
        text: "Please Enter Valid Email!",
        icon: "warning",
        buttons: false,
        timer: 2000,
      });
      return false;
    }
    setSendingEmail(true)
    const userData = {
      email
    }
    axios
      .post('https://admin.sliceguys.co.uk/forgot-password/', userData)
      .then((res) => {
        if (res.data.result) {
          setSendingEmail(false)
          setShowOtp(true);
          swal({
            text: "An Email With OTP Sent To Your Email.",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
        } else {
          setSendingEmail(false)
          swal({
            text: res.data.message,
            icon: "warning",
            buttons: false,
            timer: 2000,
          });
        }
      }).catch((e) => {
        console.log(e);
        setSendingEmail(false)
      });
  };
  const handleChangePassword = (email, otp, newPassword) => {
    setSubmittingOtp(true)
    const data = {
      otp,
      password: newPassword,
      email
    }
    axios
      .post('https://admin.sliceguys.co.uk/change-password/', data)
      .then((res) => {
        if (res.data.result) {
          setSubmittingOtp(false)
          swal({
            text: "Password Updated.",
            icon: "success",
            buttons: false,
            timer: 2000,
          });
          handleClose();
        } else {
          setSubmittingOtp(false)
          swal({
            text: res.data.message,
            icon: "warning",
            buttons: false,
            timer: 2000,
          });
        }
      }).catch((e) => {
        console.log(e);
        swal({
          text: "An Error Occured! Try Again.",
          icon: "warning",
          buttons: false,
          timer: 2000,
        });
        setSubmittingOtp(false)
      });
  }

  return (
    <div>
      <p className="txt mt-2" style={{ cursor: 'pointer' }}>
        <Button variant="contained" style={{ backgroundColor: 'black', color: 'white', height: '30px' }}
          onClick={handleOpen}
        >Forgot Password</Button>
      </p>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div>
            {(!showOtp) && (<Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Insert Email For Sending OTP
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Email"
                  type="email"
                  placeholder='Enter your email'
                  onChange={(e) => setEmail(e.target.value)}
                />
                <LoadingButton
                  variant="contained"
                  style={{ backgroundColor: 'black', color: 'white' }}
                  loading={sendingEmail}
                  disabled={sendingEmail}
                  onClick={handleSubmit}
                  loadingPosition="end"
                  endIcon={<SendIcon />}
                >Send</LoadingButton>

              </Typography>


            </Box>)}
            {(showOtp) && (<Box sx={style}>
              <Typography id="transition-modal-title" variant="h6" component="h2">
                Reset Password
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <TextField
                  required
                  id="outlined-required"
                  label="OTP"
                  type="text"
                  placeholder='Enter OTP'
                  onChange={(e) => setOtp(e.target.value)}
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <TextField
                  required
                  id="outlined-required"
                  label="New Password"
                  type="password"
                  placeholder='Enter New Password'
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Confirm New Password"
                  type="password"
                  placeholder='Confirm New Password'
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                />
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                <LoadingButton
                  variant="contained"
                  style={{ backgroundColor: 'black', color: 'white' }}
                  loading={submittingOtp}
                  disabled={submittingOtp}
                  onClick={handlePasswordSubmit}
                  loadingPosition="end"
                  endIcon={<ArrowUpwardIcon />}
                >Submit</LoadingButton>
              </Typography>
            </Box>)}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
