import React from "react";
import "./Payment.css";
function terms() {
    return (
        <div className="terms">
            <h3>T&C - PLEASE READ BEFORE PLACING YOUR ORDER…</h3>

            <p>Opening Hours:</p>
            <p>SUN - THUR: 12:00pm - 11.15pm</p>
            <p>FRIDAY: 2:00pm - 12:45am</p>
            <p>SAT: 12:00pm - 12:45am</p>
            <br />
            <p>- Please place your order during store opening hours only.</p>
            <br />
            <p>- If you make an order outside of operating hours, you may still get charged if paying by card and you will not be entitled to a refund. (Your order will also not be delivered).</p>
            <p>- We will NOT accept orders from 15 minutes before store closing time.</p>
            <br />
            <p>Delivery Radius</p>
            <p>- Our current delivery radius is 3 miles from our store (HA3 7BB). We may be able to deliver over 3 miles, delivery charges may apply. Please call the store to place your order if that is the case.</p>
            <br />
            <p>Please check your distance from the store before placing your order.</p>
            <p><a href="https://goo.gl/maps/NLozydjXqmNjZ99G8" target="_blank" rel="noreferrer">Shop</a></p>
        </div>
    )
}

export default terms;