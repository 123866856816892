import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';


export const AccountProfileDetails = ({ allitem }) => {
  const getOrderSize = (size, type, cat, description) => {
    let SizeText = "";
    if (cat === "Pizza") {
      if (size === "xl") {
        SizeText = '15"';
      }
      if (size === "l") {
        SizeText = '13"';
      }
      if (size === "m") {
        SizeText = '10"';
      }
      if (size === "s") {
        SizeText = '7"';
      }
    } else if (cat === "Wings") {
      if (size === "m") {
        SizeText = "10 Pcs - " + type;
      }
      if (size === "s") {
        SizeText = "6 Pcs - " + type;
      }
    } else if (cat === "Deals") {
      if (size === "s") {
        SizeText = description;
      }
      if (size === "m") {
        SizeText = "Medium";
      }
      if (size === "l") {
        SizeText = "Large";
      }
      if (size === "xl") {
        SizeText = "Extra Large";
      }
    }
    return SizeText;
  }
  return (
    <>
      <Paper
        elevation={3}>
        <TableContainer
          sx={{ backgroundColor: 'whitesmoke' }}
          style={{ borderRadius: '10px', marginBottom: '32px' }}>
          <Table
            aria-label="spanning table">
            <TableHead>
              <TableRow style={{ borderBottom: '1px solid black' }}>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Payment Type</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Date</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Status</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow style={{ backgroundColor: '#FDFEFE', borderBottom: '1.5px solid #E4E4E5' }}>
                <TableCell align="center">{allitem.paymentType}</TableCell>
                <TableCell align="center">{allitem.date}</TableCell>
                <TableCell align="center">{allitem.status}</TableCell>
                <TableCell align="center">{allitem.note}</TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper
        elevation={3}>
        <TableContainer
          sx={{ backgroundColor: 'whitesmoke' }}
          style={{ borderRadius: '10px' }}>
          <Table
            aria-label="spanning table">
            <TableHead>
              <TableRow style={{ borderBottom: '1px solid black' }}>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Products</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Description</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Quantity</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allitem.items ? allitem.items.map((order) => (
                <TableRow
                  key={order.id}
                  style={{ backgroundColor: '#FDFEFE', borderBottom: '1.5px solid #E4E4E5' }}
                >
                  <TableCell align="center">
                    {order.title}
                    {order.category === 'Pizza' || order.category === 'Wings' || order.category === 'Deals' ? (<br />) : ''}
                    {order.category === 'Pizza' || order.category === 'Wings' || order.category === 'Deals' ? (<label>({getOrderSize(order.size, order.type, order.category, order.description)})</label>) : ''}
                    {order.category === 'Pizza' || order.category === 'Wings' ? (<br />) : ''}
                    {order.category === 'Pizza' ? `(${order.crust === "deep_pan" ? "Deep Pan" : order.crust === "thin_crust" ? "Tnin Crust" : "Stuffed Crush"})` : ""}
                  </TableCell>
                  {order.topping ? <TableCell align="center">{order.topping.slice(0, order.topping.length - 2)}</TableCell>
                    : <TableCell align="center">- - -</TableCell>}
                  <TableCell align="center">{order.quantity}</TableCell>
                  <TableCell align="center">£ {parseFloat(order.totalQuantityPrice)}</TableCell>
                </TableRow>
              )) : <TableRow>
                <TableCell align="center">loadding</TableCell>
              </TableRow>}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}></TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}></TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>Total Price</TableCell>
                <TableCell align="center"
                  style={{ color: '#3C3C3D', fontWeight: '900' }}>£ {allitem.items ? allitem.items.reduce((total, prd) => total + parseFloat(prd?.totalQuantityPrice), 0).toFixed(2) : <span>loadding</span>}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Paper>
    </>

  );
};
