import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountProfile } from "../components/account/account-profile";
import { AccountProfileDetails } from "../components/account/account-profile-details";
import { DashboardLayout } from "../components/layout/dashboard-layout";
import useAuth from "../Context/useAuth";

const Orders = () => {
	const { ordersId } = useParams();
	const [singleUser, setSingleUser] = useState(null);
	const { status } = useAuth();

	useEffect(() => {
		fetch(
			`https://admin.sliceguys.co.uk/buy-product/${ordersId}`
		)
			.then((res) => res.json())
			.then((data) => {
				setSingleUser(data)
				console.log(data);
			});
	}, [ordersId, status]);

	return (
		<Box
			component="main"
			sx={{
				flexGrow: 1,
				py: 3,
			}}
			className="detailsBackground"
		>
			<Container style={{ maxWidth: "100%" }}>
				<Grid container spacing={3}>
					<Grid item lg={3} md={3} sm={4} xs={12}>
						{singleUser && <AccountProfile id={singleUser.addressId} />}
					</Grid>
					<Grid item lg={9} md={9} sm={8} xs={12}>
						{singleUser && (
							<AccountProfileDetails allitem={singleUser} />
						)}
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

Orders.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default Orders;
