import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import axios from "axios";
import { useEffect, useState } from "react";
import Cart from "../../components/Cart/Cart";
import Loadding from "../../components/loading/Loadding";
import Ingreadients from "../../components/Products/Ingreadients";
import useAuth from "../../Context/useAuth";
import "./favourite.css";

function Favourite() {
	const { user, ProductsDatas, setProductsDatas } = useAuth();
	const [favdata, setFavData] = useState([]);
	const [removeState, setRemoveState] = useState(false);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/allproduts")
			.then((res) => res.json())
			.then((data) => {
				setProductsDatas(data)
			});
	}, [setProductsDatas]);

	useEffect(() => {
		const url = `https://admin.sliceguys.co.uk/favouritelist/${user.email}`;
		fetch(url)
			.then((res) => res.json())
			.then((data) => {
				setFavData(data);
				setPending(false);
			});
	}, [user.email, removeState, setPending]);

	const data = ProductsDatas?.filter((value) => {
		return favdata.find((da) => {
			return da.id === value._id ? 1 : 0;
		});
	});

	const handleRemove = (id) => {
		const proced = window.confirm("Do you want to Remove this product from favourite ?");
		if (proced) {
			axios
				.delete(
					`https://admin.sliceguys.co.uk/favouritelist/${id}`
				)
				.then((res) => {
					if (res.data.deletedCount > 0) {
						setRemoveState(!removeState);
					}
				});
		}
	};

	return (
		<Grid container spacing={2}>
			<Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
				<div className="favourite">
					<h1 className="favTitle">My Favourites</h1>
					{(data.length > 0 && !pending) && (<Grid container spacing={2}>
						{data
							? data.map((product) => (
								<Product
									key={product._id}
									allProducts={ProductsDatas}
									product={product}
									handleRemove={handleRemove}
								/>
							))
							: ""}
					</Grid>)}
					{(data.length === 0 && !pending) && (
						<h2 className="favTitle">
							Click the  "
							<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium buttonId css-umgocn-MuiSvgIcon-root" style={{ height: "24px" }} focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FavoriteTwoToneIcon">
								<path style={{
									color: "white",
									cursor: "pointer",
									opacity: ".7"
								}} d="M16.5 5c-1.54 0-3.04.99-3.56 2.36h-1.87C10.54 5.99 9.04 5 7.5 5 5.5 5 4 6.5 4 8.5c0 2.89 3.14 5.74 7.9 10.05l.1.1.1-.1C16.86 14.24 20 11.39 20 8.5c0-2-1.5-3.5-3.5-3.5z">
								</path>
								<path style={{
									color: 'black',
									cursor: "pointer",
								}} d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z">
								</path>
							</svg>"
							icon on a product you want to add to your favourites.
						</h2>)}
					{(pending && data.length === 0) && (
						<Box style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							paddingTop: "20px",
						}}
						>
							<Loadding></Loadding>
						</Box>)}
				</div>
			</Grid>
			<Grid
				item
				xl={3}
				lg={3}
				md={4}
				sm={0}
				xs={0}
				sx={{ padding: "0px" }}
				className="sideCartItemNone"
			>
				<Cart />
			</Grid>
		</Grid>
	);
}

// function Product({ product, addToCart, handleRemove }) {
// 	const [selectedValue, setSelectedValue] = useState("s");

// 	const priceTopinsdis =
// 		selectedValue === "s"
// 			? product.regularDiscount
// 			: selectedValue === "m"
// 				? product.mediumDiscount
// 				: selectedValue === "l"
// 					? product.largeDiscount
// 					: selectedValue === "xl"
// 						? product.extraLargeDiscount
// 						: product.regularDiscount;

// 	const priceDiscount = parseFloat(priceTopinsdis).toFixed(2);

// 	const updatedProduct = {
// 		...product,
// 		price: priceDiscount,
// 		size: selectedValue,
// 		quantity: 1,
// 		totalQuantityPrice: priceDiscount,
// 	};

// 	return (
// 		<Grid item xl={3} lg={4} md={3} sm={6} xs={12}>
// 			<div style={{ padding: "10px" }} className="item">
// 				<div className="img">
// 					<img src={`data:image/*;base64, ${product.file}`} alt="" />
// 				</div>

// 				<div className="details">
// 					<div className="price">
// 						<b>£ {priceDiscount}</b>
// 						<div>
// 							<RemoveCircleIcon
// 								onClick={() => handleRemove(product._id)}
// 								sx={{ mb: 1, cursor: "pointer", color: "#ffe500" }}
// 							/>
// 						</div>
// 					</div>
// 					<div className="title">
// 						<b>{product.title}</b>
// 					</div>
// 					<p style={{ marginBottom: "0px", marginTop: "2px" }}>
// 						{product.description}
// 					</p>
// 					<div className="top">
// 						<div className="size">
// 							{(product?.mediumDiscount || product?.largeDiscount) && (
// 								<div className="s">
// 									<Radio
// 										checked={selectedValue === "s" && true}
// 										onClick={() => setSelectedValue("s")}
// 										sx={{
// 											color: "#8f9d9f",
// 											paddingRight: "10px",
// 											"&.Mui-checked": {
// 												color: "#ffe500",
// 											},
// 										}}
// 									/>
// 									<p>S</p>
// 								</div>
// 							)}
// 							{product?.mediumDiscount && (
// 								<div className="s">
// 									<Radio
// 										checked={selectedValue === "m" && true}
// 										onClick={() => setSelectedValue("m")}
// 										sx={{
// 											color: "#8f9d9f",
// 											paddingRight: "10px",
// 											"&.Mui-checked": {
// 												color: "#ffe500",
// 											},
// 										}}
// 									/>
// 									<p>M</p>
// 								</div>
// 							)}
// 							{product.largeDiscount && (
// 								<div className="s">
// 									<Radio
// 										checked={selectedValue === "l" && true}
// 										onClick={() => setSelectedValue("l")}
// 										sx={{
// 											color: "#8f9d9f",
// 											paddingRight: "10px",
// 											"&.Mui-checked": {
// 												color: "#ffe500",
// 											},
// 										}}
// 									/>
// 									<p>L</p>
// 								</div>
// 							)}
// 							{product.extraLargeDiscount && (
// 								<div className="s">
// 									<Radio
// 										checked={selectedValue === "xl" && true}
// 										onClick={() => setSelectedValue("xl")}
// 										sx={{
// 											color: "#8f9d9f",
// 											paddingRight: "10px",
// 											"&.Mui-checked": {
// 												color: "#ffe500",
// 											},
// 										}}
// 									/>
// 									<p>XL</p>
// 								</div>
// 							)}
// 						</div>
// 					</div>
// 					<Ingreadients updatedProduct={updatedProduct}></Ingreadients>
// 				</div>
// 			</div>
// 		</Grid>
// 	);
// }

function Product({ product, allProducts, handleRemove }) {
	const [selectedValue, setSelectedValue] = useState("");
	const [selectedCrust, setSelectedCrust] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const { addToCart } = useAuth();

	useEffect(() => {
		if (parseFloat(product?.regularDiscount) !== 0) {
			setSelectedValue("s")
		} else {
			setSelectedValue("m")
		}
		if (product.category === "Pizza") {
			setSelectedCrust("deep_pan")
		}
		if (product.category === "Wings") {
			setSelectedType("normal")
		}
	}, [product.category, product?.regularDiscount]);
	useEffect(() => {
		if (selectedValue === "s") {
			setSelectedCrust("deep_pan")
		}
	}, [selectedValue]);

	const InggedientAvailableCategories = ["Pizza", "Wings", "Vegan", "Sides", "Shakes", "Deals"];

	const priceTopinsdis =
		selectedValue === "s"
			? parseFloat(product.regularDiscount)
			: selectedValue === "m"
				? parseFloat(product.mediumDiscount)
				: selectedValue === "l"
					? parseFloat(product.largeDiscount)
					: selectedValue === "xl"
						? parseFloat(product.extraLargeDiscount)
						: parseFloat(product.regularDiscount);

	const crustPriceCheck = selectedCrust === "deep_pan"
		? parseFloat(product.deep_pan)
		: selectedCrust === "thin_crust"
			? parseFloat(product.thin_crust)
			: selectedCrust === "stuffed_crust"
				? parseFloat(product.stuffed_crust)
				: parseFloat(product.deep_pan);

	const typePriceCheck = selectedType === "normal"
		? parseFloat(product.normal)
		: selectedType === "boneless"
			? parseFloat(product.boneless)
			: parseFloat(product.normal);

	const crustPrice = isNaN(crustPriceCheck) ? 0 : parseFloat(crustPriceCheck);
	const typePrice = isNaN(typePriceCheck) ? 0 : parseFloat(typePriceCheck)
	const priceDiscount = parseFloat(parseFloat(priceTopinsdis) + parseFloat(crustPrice) + parseFloat(typePrice)).toFixed(2);

	const updatedProduct = {
		...product,
		price: priceDiscount,
		size: selectedValue,
		crust: selectedCrust,
		type: selectedType,
		quantity: 1,
		totalQuantityPrice: priceDiscount,
	};

	const data = {
		...updatedProduct,
		toopingNames: '',
		totalToopingPrice: 0,
		price: parseFloat(updatedProduct.price),
		totalQuantityPrice: parseFloat(updatedProduct.price),
	};

	const handleAddToCart = () => {
		addToCart(data);
	}

	return (
		<Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
			<div style={{ padding: "10px", position: "relative" }} className="item">
				<div className="img">
					<img src={`data:image/*;base64, ${product.file}`} alt="" />
				</div>

				<div className="details">
					<div className="price">
						<b>£ {priceDiscount}</b>
						<div>
							<RemoveCircleIcon
								onClick={() => handleRemove(product._id)}
								sx={{ mb: 1, cursor: "pointer", color: "#ffe500" }}
							/>
						</div>
					</div>
					<div className="title" style={{ lineHeight: '18px', textAlign: 'left' }}>
						<b>{product.title}</b>
					</div>
					<p className="description">{product.description}</p>
					{/* Size For Pizza && Wings */}
					<div className="top">
						<div className="size">
							{((product?.mediumDiscount || product?.largeDiscount) && parseFloat(product?.regularDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "s" && true}
										onClick={() => setSelectedValue("s")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>{product.category === "Pizza" || product.category === "Deals" ? '7"' : "6 Pcs"}</p>
								</div>
							)}
							{product?.mediumDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "m" && true}
										onClick={() => setSelectedValue("m")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>{product.category === "Pizza" || product.category === "Deals" ? '10"' : "10 Pcs"}</p>
								</div>
							)}
							{product.largeDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "l" && true}
										onClick={() => setSelectedValue("l")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>13"</p>
								</div>
							)}
							{product.extraLargeDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "xl" && true}
										onClick={() => setSelectedValue("xl")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>15"</p>
								</div>
							)}
						</div>
					</div>
					{/* Crust For Pizza */}
					<div className="top">
						<div className="size">
							{(product?.deep_pan) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "deep_pan" && true}
										onClick={() => setSelectedCrust("deep_pan")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Deep Pan</p>
								</div>
							)}
							{(product?.thin_crust) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "thin_crust" && true}
										onClick={() => setSelectedCrust("thin_crust")}
										disabled={selectedValue === "s"}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Thin Crust</p>
								</div>
							)}
							{(product.stuffed_crust) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "stuffed_crust" && true}
										onClick={() => setSelectedCrust("stuffed_crust")}
										disabled={selectedValue === "s"}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Stuffed Crust</p>
								</div>
							)}
						</div>
					</div>
					{/* Type For Wings */}
					<div className="top">
						<div className="size">
							{(product?.normal) && (
								<div className="s">
									<Radio
										checked={selectedType === "normal" && true}
										onClick={() => setSelectedType("normal")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Normal</p>
								</div>
							)}
							{(product?.boneless) && (
								<div className="s">
									<Radio
										checked={selectedType === "boneless" && true}
										onClick={() => setSelectedType("boneless")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Boneless</p>
								</div>
							)}
						</div>
					</div>
					{InggedientAvailableCategories.includes(product.category) ? (<Ingreadients updatedProduct={updatedProduct} products={allProducts}></Ingreadients>) :
						(<div className="addButton">
							<Button
								className="btn-add"
								variant="contained"
								onClick={handleAddToCart}
							>
								Add to Cart
							</Button>
						</div>)}
				</div>
			</div>
		</Grid>

	);
}

export default Favourite;
