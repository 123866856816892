import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import useAuth from "../../Context/useAuth";
import Ingreadients from "./Ingreadients";

function Products({ ProductsD, allProducts, favdata, removeState, setRemoveState }) {
	return (
		<div className="product" style={{ margin: "1rem 0px 10rem 0px" }}>
			<h6 style={{ fontSize: '24px', fontWidth: 'bold', margin: '5px 0px', fontFamily: 'Bebas Neue', fontWeight: '400' }}>Products</h6>
			<Grid container spacing={4} style={{ paddingTop: "1rem" }}>
				{ProductsD.map((product) => (
					<Product key={product._id} product={product} allProducts={allProducts} isFav={favdata.find(f => f.id === product._id)} removeState={removeState} setRemoveState={setRemoveState} />
				))}
			</Grid>
		</div>
	);
}

function Product({ product, allProducts, isFav, removeState, setRemoveState }) {
	const { user } = useAuth();
	const [selectedValue, setSelectedValue] = useState("");
	const [selectedCrust, setSelectedCrust] = useState(null);
	const [selectedType, setSelectedType] = useState(null);
	const { addToCart } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		if (parseFloat(product?.regularDiscount) !== 0) {
			setSelectedValue("s");
		} else if (parseFloat(product?.mediumDiscount) !== 0) {
			setSelectedValue("m");
		} else if (parseFloat(product?.largeDiscount) !== 0) {
			setSelectedValue("l");
		}
		if (product.category === "Pizza") {
			setSelectedCrust("deep_pan")
		}
		if (product.category === "Wings") {
			setSelectedType("normal")
		}
	}, [product.category, product?.regularDiscount, product?.mediumDiscount, product?.largeDiscount]);
	useEffect(() => {
		if (selectedValue === "s") {
			setSelectedCrust("deep_pan")
		}
	}, [selectedValue]);

	const handleFavourites = (id) => {
		if (!isFav) {
			if (!user?.email) {
				alert("Please signin or register");
				navigate("/signin");
			} else {
				const data = {
					id,
					email: user.email,
				};
				axios
					.post(
						"https://admin.sliceguys.co.uk/favouritelist",
						data
					)
					.then((res) => {
						if (res.data.insertedId) {
							setRemoveState(!removeState)
							swal({
								text: "Successfully Added To Favourates",
								icon: "success",
								buttons: false,
								timer: 2000,
							});
						} else if (res.data.messege) {
							swal({
								text: res.data.messege,
								icon: "warning",
								buttons: false,
								timer: 2000,
							});
						}
					});
			}
		} else {
			handleRemove(id)
		}

	};

	const handleRemove = (id) => {
		const proced = window.confirm("Do you want to Remove this product from favourite ?");
		if (proced) {
			axios
				.delete(
					`https://admin.sliceguys.co.uk/favouritelist/${id}`
				)
				.then((res) => {
					if (res.data.deletedCount > 0) {
						setRemoveState(!removeState)
						swal({
							text: "Successfully Removed From Favourates",
							icon: "success",
							buttons: false,
							timer: 2000,
						});
					}
				});
		}
	};

	const InggedientAvailableCategories = ["Pizza", "Wings", "Vegan", "Sides", "Shakes", "Deals", "Nachos"];

	const priceTopinsdis =
		selectedValue === "s"
			? parseFloat(product.regularDiscount)
			: selectedValue === "m"
				? parseFloat(product.mediumDiscount)
				: selectedValue === "l"
					? parseFloat(product.largeDiscount)
					: selectedValue === "xl"
						? parseFloat(product.extraLargeDiscount)
						: parseFloat(product.regularDiscount);

	const crustPriceCheck = selectedCrust === "deep_pan"
		? parseFloat(product.deep_pan)
		: selectedCrust === "thin_crust"
			? parseFloat(product.thin_crust)
			: selectedCrust === "stuffed_crust"
				? parseFloat(product.stuffed_crust)
				: parseFloat(product.deep_pan);

	const typePriceCheck = selectedType === "normal"
		? parseFloat(product.normal)
		: selectedType === "boneless"
			? parseFloat(product.boneless)
			: parseFloat(product.normal);

	const crustPrice = isNaN(crustPriceCheck) ? 0 : parseFloat(crustPriceCheck);
	const typePrice = isNaN(typePriceCheck) ? 0 : parseFloat(typePriceCheck)
	const priceDiscount = parseFloat(parseFloat(priceTopinsdis) + parseFloat(crustPrice) + parseFloat(typePrice)).toFixed(2);

	const updatedProduct = {
		...product,
		price: priceDiscount,
		size: selectedValue,
		crust: selectedCrust,
		type: selectedType,
		quantity: 1,
		totalQuantityPrice: priceDiscount,
	};

	const data = {
		...updatedProduct,
		toopingNames: '',
		totalToopingPrice: 0,
		price: parseFloat(updatedProduct.price),
		totalQuantityPrice: parseFloat(updatedProduct.price),
	};

	const price = (p) => {
		const price = parseFloat(p);
		if (price === 0.00 || price === 0) {
			return 'Free';
		} else if (price < 1) {
			return `${price * 100}p`;
		} else {
			return `£${price}`
		}
	}

	const handleAddToCart = () => {
		addToCart(data);
	}

	return (
		<Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
			<div style={{ padding: "10px", position: "relative" }} className="item">
				<div className="img">
					<img src={`data:image/*;base64, ${product.file}`} alt="" />
				</div>

				<div className="details">
					<div className="price">
						<span style={{ fontSize: "1.5rem" }}>
							<b>{price(priceDiscount)}</b>
						</span>
						<div>
							{/* <FavoriteTwoToneIcon
								onClick={() => handleFavourites(product._id)}
								className="buttonId"
								sx={{
									color: isFav && "yellow",
									mb: 1,
									cursor: "pointer",
								}}
							/> */}
							<svg
								onClick={() => handleFavourites(product._id)}
								className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium buttonId css-umgocn-MuiSvgIcon-root"
								style={{ height: "24px" }}
								focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="FavoriteTwoToneIcon">
								<path style={{
									fill: isFav ? "yellow" : "white",
									mb: 1,
									cursor: "pointer",
									opacity: isFav ? "1" : ".7"
								}} d="M16.5 5c-1.54 0-3.04.99-3.56 2.36h-1.87C10.54 5.99 9.04 5 7.5 5 5.5 5 4 6.5 4 8.5c0 2.89 3.14 5.74 7.9 10.05l.1.1.1-.1C16.86 14.24 20 11.39 20 8.5c0-2-1.5-3.5-3.5-3.5z">
								</path>
								<path style={{
									fill: 'black',
									mb: 1,
									cursor: "pointer",
								}} d="M16.5 3c-1.74 0-3.41.81-4.5 2.09C10.91 3.81 9.24 3 7.5 3 4.42 3 2 5.42 2 8.5c0 3.78 3.4 6.86 8.55 11.54L12 21.35l1.45-1.32C18.6 15.36 22 12.28 22 8.5 22 5.42 19.58 3 16.5 3zm-4.4 15.55-.1.1-.1-.1C7.14 14.24 4 11.39 4 8.5 4 6.5 5.5 5 7.5 5c1.54 0 3.04.99 3.57 2.36h1.87C13.46 5.99 14.96 5 16.5 5c2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z">
								</path>
							</svg>
						</div>
					</div>
					<div className="title">
						<b>{product.title}</b>
					</div>
					<p className="description">{product.description}</p>
					{/* Size For Pizza && Wings */}
					<div className="top">
						<div className="size">
							{((product?.mediumDiscount || product?.largeDiscount) && parseFloat(product?.regularDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "s" && true}
										onClick={() => setSelectedValue("s")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>{product.category === "Pizza" || product.category === "Deals" ? '7"' : "6 Pcs"}</p>
								</div>
							)}
							{(product?.mediumDiscount && parseFloat(product?.mediumDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "m" && true}
										onClick={() => setSelectedValue("m")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>{product.category === "Pizza" || product.category === "Deals" ? '10"' : "10 Pcs"}</p>
								</div>
							)}
							{(product.largeDiscount && parseFloat(product?.largeDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "l" && true}
										onClick={() => setSelectedValue("l")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>13"</p>
								</div>
							)}
							{product.extraLargeDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "xl" && true}
										onClick={() => setSelectedValue("xl")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>15"</p>
								</div>
							)}
						</div>
					</div>
					{/* Crust For Pizza */}
					<div className="top">
						<div className="size">
							{(product?.deep_pan) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "deep_pan" && true}
										onClick={() => setSelectedCrust("deep_pan")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Deep Pan</p>
								</div>
							)}
							{(product?.thin_crust) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "thin_crust" && true}
										onClick={() => setSelectedCrust("thin_crust")}
										disabled={selectedValue === "s"}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Thin Crust</p>
								</div>
							)}
							{(product.stuffed_crust) && (
								<div className="s">
									<Radio
										checked={selectedCrust === "stuffed_crust" && true}
										onClick={() => setSelectedCrust("stuffed_crust")}
										disabled={selectedValue === "s"}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Stuffed Crust</p>
								</div>
							)}
						</div>
					</div>
					{/* Type For Wings */}
					<div className="top">
						<div className="size">
							{(product?.normal) && (
								<div className="s">
									<Radio
										checked={selectedType === "normal" && true}
										onClick={() => setSelectedType("normal")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Normal</p>
								</div>
							)}
							{(product?.boneless) && (
								<div className="s">
									<Radio
										checked={selectedType === "boneless" && true}
										onClick={() => setSelectedType("boneless")}
										sx={{
											color: "#8f9d9f",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "#ffe500",
											},
										}}
									/>
									<p>Boneless</p>
								</div>
							)}
						</div>
					</div>
					{InggedientAvailableCategories.includes(product.category) ? (<Ingreadients updatedProduct={updatedProduct} products={allProducts}></Ingreadients>) :
						(<div className="addButton">
							<Button
								className="btn-add"
								variant="contained"
								onClick={handleAddToCart}
							>
								Add to Cart
							</Button>
						</div>)}
				</div>
			</div>
		</Grid>

	);
}
export default Products;
