import { Paper } from "@mui/material";
import pizzaCoverMobile from '../../assets/images/base_line.png';
import pizzaCover from '../../assets/images/pizza-cover.jpeg';
import "./category.css";

function Category({ setIsActiveCat, isActiveCat, alldata }) {

  return (
    <div className="category">
      <h6 style={{ fontSize: '24px', fontWidth: 'bold', margin: '5px 0px', fontFamily: 'Bebas Neue', fontWeight: '400' }}>Category</h6>
      <div className="category_item">
        {
          alldata.map((data, index) => <Paper
            key={index}
            className="paper"
            onClick={() => {
              setIsActiveCat(data.categoriName);
            }}
            style={{
              background:
                isActiveCat === data.categoriName ? "#ffe500" : "#f7f7f7",
              cursor: 'pointer'
            }}
          >
            <img
              src={`data:image/*;base64, ${data.file}`}
              alt=""
            />

            <span
            >
              {data.categoriName}
            </span>
          </Paper>)
        }
      </div>
      {(isActiveCat === "Pizza" || isActiveCat === "Deals") && <div>
        <img src={pizzaCover} className="pizza-d" alt="pizza-img" />
        <img src={pizzaCoverMobile} className="pizza-m" alt="pizza-img-m" />
      </div>}
    </div>
  );
}

export default Category;
