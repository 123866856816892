import FolderIcon from "@mui/icons-material/Folder";
import { Avatar, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { DashboardLayout } from "../layout/dashboard-layout";
import "./form.css";

const EditProducts = () => {
	const { id } = useParams();

	const [alldata, setAlldata] = useState([]);
	const [products, setProducts] = useState({});
	const [cat, setCat] = useState([]);
	const navigate = useNavigate();

	const handleCat = (e) => {
		setCat(e.target.value)
	}
	// const getProductData = () => {
	// 	const url = `https://admin.sliceguys.co.uk/allproduts/${id}`;
	// 	fetch(url)
	// 		.then((res) => res.json())
	// 		.then((data) => {
	// 			setProducts(data)
	// 			setCat(data.category)
	// 		});
	// }
	useEffect(() => {
		const url = `https://admin.sliceguys.co.uk/allproduts/${id}`;
		fetch(url)
			.then((res) => res.json())
			.then((data) => {
				setProducts(data)
				setCat(data.category)
			});
	}, [id]);
	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/allcategori")
			.then((res) => res.json())
			.then((data) => {
				setAlldata(data);
			});
	}, []);

	const [myfiles, setMyFiles] = useState(null);
	const inputRef = useRef(HTMLInputElement);
	const getImagePreview = (e) => {
		let image = URL.createObjectURL(e.target.files[0]);
		let imagediv = document.getElementById("preview");
		imagediv.src = image;
		setMyFiles(e.target.files[0]);
	};


	const { register, handleSubmit } = useForm();

	// const onSubmit = (data) => {
	// 	axios
	// 		.put(
	// 			`https://admin.sliceguys.co.uk/allproduts/${id}`,
	// 			data
	// 		)
	// 		.then((res) => {
	// 			if (res.data) {
	// 				swal({
	// 					text: "Successfully updated your product",
	// 					icon: "success",
	// 					buttons: false,
	// 					timer: 2000,
	// 				});
	// 			}
	// 		});
	// };

	const onSubmit = async (data) => {
		if (myfiles?.size > 2000000) {
			swal({
				text: "Your image is over 2MB",
				icon: "warning",
			});
		} else {
			const formData = new FormData();
			formData.append("title", data.title);
			formData.append("category", cat);
			formData.append("description", data.description);
			// formData.append("regularprice", data.regularPrice);
			formData.append("regularDiscount", data.regularDiscount);
			// formData.append("mediumPrice", data.mediumPrice);
			formData.append("mediumDiscount", data.mediumDiscount ? data.mediumDiscount : '');
			// formData.append("largePrice", data.largePrice);
			formData.append("largeDiscount", data.largeDiscount ? data.largeDiscount : '');
			formData.append("extraLargeDiscount", data.extraLargeDiscount ? data.extraLargeDiscount : '');
			if (cat === "Pizza") {
				formData.append("deep_pan", data.deep_pan ? data.deep_pan : null);
				formData.append("thin_crust", data.thin_crust ? data.thin_crust : null);
				formData.append("stuffed_crust", data.stuffed_crust ? data.stuffed_crust : null);
			}
			if (cat === "Wings") {
				formData.append("normal", data.normal ? data.normal : null);
				formData.append("boneless", data.boneless ? data.boneless : null);
			}
			if (myfiles) {
				const fileData = await convertBase64(myfiles);
				const finalFile = await fileData.split('data:image/jpeg;base64,')[1];
				formData.append("file", finalFile);
			}


			axios
				.put(
					`https://admin.sliceguys.co.uk/allproduts/${id}`,
					formData
				)
				.then((res) => {
					if (res.data) {
						swal({
							text: "Successfully updated your product",
							icon: "success",
							buttons: false,
							timer: 2000,
						});
						navigate("/products");
					}
				}).catch((error) => {
					console.error("Error:", error);
				});

			// fetch(`https://admin.sliceguys.co.uk/allproduts/${id}`, {
			// 	method: "PUT",
			// 	body: formData,
			// }).then((res) => {
			// 	if (res.data) {
			// 		swal({
			// 			text: "Successfully updated your product",
			// 			icon: "success",
			// 			buttons: false,
			// 			timer: 2000,
			// 		});
			// 		navigate("/products");
			// 	}
			// })

		}
	};
	const convertBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const fileReader = new FileReader();
			fileReader.readAsDataURL(file);

			fileReader.onload = () => {
				resolve(fileReader.result);
			};

			fileReader.onerror = (error) => {
				reject(error);
			};
		});
	};
	const handleCancel = () => {
		navigate('/products');
	}

	return (
		<Box className="addressForm">
			{products?._id && (
				<Paper className="addressFormContainer">
					<form onSubmit={handleSubmit(onSubmit)}>
						<Box className="rowContainer">
							<Box className="rowItem">
								<img
									src={`data:image/*;base64, ${products.file}`}
									alt=""
									id="preview"
									style={{
										height: "100px",
										width: "100px",
										borderRadius: "100%",
										objectFit: "cover",
									}}
								/>
							</Box>
							<Box className="rowItem">
								<input
									type="file"
									accept="image/*"
									// {...register("imageFile")}
									onChange={getImagePreview}
									style={{ display: "none" }}
									ref={inputRef}
								/>
								<Avatar onClick={() => inputRef.current.click()}>
									<FolderIcon />
								</Avatar>
							</Box>

							<Box className="rowItem">
								<label>Product Category</label> <br />
								<select
									{...register("category")}
									defaultValue={products.category}
									style={{ height: "40px", width: "160px" }}
									onChange={handleCat}
								>
									{alldata ? (
										alldata.map((data) => (
											<option key={data._id} value={data.categoriName}>
												{data.categoriName}
											</option>
										))
									) : (
										<option value="all">All</option>
									)}
								</select>
							</Box>
						</Box>
						<Box className="rowContainer">
							<Box className="rowItem" style={{ width: "100%" }}>
								<label>Product title</label> <br />
								<input style={{ width: "100%" }}
									defaultValue={products.title}
									{...register("title")}
								/>
							</Box>
						</Box>
						<Box className="rowContainer">
							<Box className="rowItem" style={{ width: "100%" }}>
								<label>Product Description</label>
								<input style={{ width: "100%" }}
									type="text"
									defaultValue={products.description}
									{...register("description")}
								/>
							</Box>
						</Box>
						<br /><label><b>Price(s) :</b></label> <br />
						{cat !== 'Wings' && <Box className="rowContainer">
							<Box className="rowItem">
								<label>Regular Price</label>
								<input
									type="number" step="0.01"
									defaultValue={products.regularDiscount}
									{...register("regularDiscount")}
								/>
							</Box>
							{(cat === 'Pizza' || cat === 'Deals') && <Box className="rowItem">
								<label>Medium Price</label>
								<input
									type="number" step="0.01"
									defaultValue={products.mediumDiscount}
									{...register("mediumDiscount")}
								/>
							</Box>}
						</Box>}
						{(cat === 'Wings') && <Box className="rowContainer">
							<Box className="rowItem">
								<label>6 Pcs Price</label>
								<input
									type="number" step="0.01"
									defaultValue={products.regularDiscount}
									{...register("regularDiscount")}
								/>
							</Box>
							<Box className="rowItem">
								<label>10 Pcs Price</label>
								<input
									type="number" step="0.01"
									defaultValue={products.mediumDiscount}
									{...register("mediumDiscount")}
								/>
							</Box>
						</Box>}
						{(cat === 'Pizza' || cat === 'Deals') && <Box className="rowContainer">
							<Box className="rowItem">
								<label>Large Price</label>
								<input
									type="number" step="0.01"
									defaultValue={products.largeDiscount}
									{...register("largeDiscount")}
								/>
							</Box>
							<Box className="rowItem">
								<label>Extra Large size price</label> <br />
								<input type="number" step="0.01"
									defaultValue={products.extraLargeDiscount}
									{...register("extraLargeDiscount")} />
							</Box>
						</Box>}
						<br />
						{cat === 'Pizza' && (<label><b>Crust :</b></label>)}
						{cat === 'Wings' && (<label><b>Type :</b></label>)}
						<br />
						{cat === 'Pizza' && <Box className="rowContainer">
							<Box className="rowItem">
								<label>Deep Pan Addition</label> <br />
								<input type="number" step="0.01"
									defaultValue={products.deep_pan ? products.deep_pan : 0}
									{...register("deep_pan")} />
							</Box>
							<Box className="rowItem">
								<label>Thin Crust Addition</label> <br />
								<input type="number" step="0.01"
									defaultValue={products.thin_crust ? products.thin_crust : 0}
									{...register("thin_crust")} />
							</Box>

						</Box>}

						{cat === 'Pizza' && <Box className="rowContainer">
							<Box className="rowItem">
								<label>Stuffed Crust Addition</label> <br />
								<input type="number" step="0.01"
									defaultValue={products.stuffed_crust ? products.stuffed_crust : 2}
									{...register("stuffed_crust")} />
							</Box>
						</Box>}

						{cat === 'Wings' && <Box className="rowContainer">
							<Box className="rowItem">
								<label>Normal</label> <br />
								<input type="number" step="0.01" defaultValue={products.normal ? products.normal : 0} {...register("normal")} />
							</Box>
							<Box className="rowItem">
								<label>Boneless</label> <br />
								<input type="number" step="0.01" defaultValue={products.boneless ? products.boneless : 0} {...register("boneless")} />
							</Box>
						</Box>}

						<input type="submit" value="Update" className="frombutton" />
						<input type="reset" value="Cancel" onClick={handleCancel} className="frombutton" />
					</form>
				</Paper >
			)
			}
		</Box >
	);
};

EditProducts.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default EditProducts;
