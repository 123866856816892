import React from "react";
// import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const CheckOut = ({ totalPrice }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (totalPrice < 10.00) {
      swal({
        text: "Minimum order amount is £10.00",
        icon: "warning",
        buttons: false,
        timer: 2000,
      });
      return false;
    } else {
      navigate("/payment")
    }
  }

  return (
    <div className="priceDiv">
      <hr />
      <div className="price">
        <b>Total Price</b>
        <b style={{ padding: "0px 15px" }}>£ {totalPrice}</b>
      </div>

      <div className="checkoutButton">
        <div onClick={() => { handleClick() }}>
          <button>Checkout</button>
        </div>
      </div>
    </div>
  );
};

export default CheckOut;
