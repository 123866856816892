import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Card } from "@mui/material";
import Radio from "@mui/material/Radio";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ProductCard = ({ product, deleteFuction }) => {
	const [selectedValue, setSelectedValue] = useState();
	const [selectedCrust, setSelectedCrust] = useState();
	const [selectedType, setSelectedType] = useState();

	const priceTopinsdis =
		selectedValue === "s"
			? product.regularDiscount
			: selectedValue === "m"
				? product.mediumDiscount
				: selectedValue === "l"
					? product.largeDiscount
					: selectedValue === "xl"
						? product.extraLargeDiscount
						: product.regularDiscount;
	const crustPriceCheck = selectedCrust === "deep_pan"
		? parseFloat(product.deep_pan)
		: selectedCrust === "thin_crust"
			? parseFloat(product.thin_crust)
			: selectedCrust === "stuffed_crust"
				? parseFloat(product.stuffed_crust)
				: parseFloat(product.deep_pan);
	const typePriceCheck = selectedType === "normal"
		? parseFloat(product.normal)
		: selectedType === "boneless"
			? parseFloat(product.boneless)
			: parseFloat(product.normal);

	const crustPrice = isNaN(crustPriceCheck) ? 0 : parseFloat(crustPriceCheck);
	const typePrice = isNaN(typePriceCheck) ? 0 : parseFloat(typePriceCheck)
	const priceDiscount = parseFloat(parseFloat(priceTopinsdis) + parseFloat(crustPrice) + parseFloat(typePrice)).toFixed(2);

	useEffect(() => {
		if (parseFloat(product?.regularDiscount) !== 0) {
			setSelectedValue("s");
		} else if (parseFloat(product?.mediumDiscount) !== 0) {
			setSelectedValue("m");
		} else if (parseFloat(product?.largeDiscount) !== 0) {
			setSelectedValue("l");
		}

		setSelectedCrust("deep_pan")
		setSelectedType("normal")
	}, [product?.regularDiscount, product?.mediumDiscount, product?.largeDiscount]);

	const price = (p) => {
		const price = parseFloat(p);
		if (price === 0.00 || price === 0) {
			return 'Free';
		} else if (price < 1) {
			return `${price * 100}p`;
		} else {
			return `£${price}`
		}
	}

	return (
		<Card style={{ height: "100%" }}>
			<div style={{ padding: "10px" }} className="item">
				<div className="img">
					<img src={`data:image/*;base64, ${product.file}`} alt="" />
				</div>

				<div className="details">
					<div className="price">
						<b>{price(priceDiscount)}</b>
					</div>
					<div className="title" style={{ margin: "1rem 0" }}>
						<b>{product.title}</b>
					</div>
					{/* Size For Pizza */}
					{(product?.category === "Pizza" || product?.category === "Deals") && (<div className="top" style={{ minHeight: "80px" }}>
						<div className="size" style={{ margin: "10px 0px" }}>
							<div className="s">
								<p>Size: </p>
							</div>
							{(parseFloat(product?.regularDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "s" && true}
										onClick={() => setSelectedValue("s")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>7"</p>
								</div>
							)}
							{(product?.mediumDiscount && parseFloat(product?.mediumDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "m" && true}
										onClick={() => setSelectedValue("m")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>10"</p>
								</div>
							)}
							{(product?.largeDiscount && parseFloat(product?.largeDiscount) !== 0) && (
								<div className="s">
									<Radio
										checked={selectedValue === "l" && true}
										onClick={() => setSelectedValue("l")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>13"</p>
								</div>
							)}
							{product?.extraLargeDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "xl" && true}
										onClick={() => setSelectedValue("xl")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>15"</p>
								</div>
							)}
						</div>
					</div>)}
					{/* Size For Wings */}
					{product?.category === "Wings" && (<div className="top" style={{ minHeight: "80px" }}>
						<div className="size" style={{ margin: "10px 0px" }}>
							<div className="s">
								<p>Quantity: </p>
							</div>
							{product?.regularDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "s" && true}
										onClick={() => setSelectedValue("s")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>6 Pcs</p>
								</div>
							)}
							{product?.mediumDiscount && (
								<div className="s">
									<Radio
										checked={selectedValue === "m" && true}
										onClick={() => setSelectedValue("m")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>10 Pcs</p>
								</div>
							)}
						</div>
					</div>)}

					{/* Crust For Pizza */}
					{(product?.category === "Pizza" && product?.stuffed_crust) && (<div className="top" style={{ minHeight: "80px" }}>
						<div className="size" style={{ margin: "10px 0px" }}>
							<div className="s">
								<p>Crust: </p>
							</div>
							{product?.deep_pan && (
								<div className="s">
									<Radio
										checked={selectedCrust === "deep_pan" && true}
										onClick={() => setSelectedCrust("deep_pan")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>Deep Pan</p>
								</div>
							)}
							{product?.thin_crust && (
								<div className="s">
									<Radio
										checked={selectedCrust === "thin_crust" && true}
										onClick={() => setSelectedCrust("thin_crust")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>Thin Crust</p>
								</div>
							)}
							{product?.stuffed_crust && (
								<div className="s">
									<Radio
										checked={selectedCrust === "stuffed_crust" && true}
										onClick={() => setSelectedCrust("stuffed_crust")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>Stuffed Crust</p>
								</div>
							)}
						</div>
					</div>)}

					{/* Type For Wings */}
					{(product?.category === "Wings" && product?.normal) && (<div className="top" style={{ minHeight: "80px" }}>
						<div className="size" style={{ margin: "10px 0px" }}>
							<div className="s">
								<p>Type: </p>
							</div>
							{product?.normal && (
								<div className="s">
									<Radio
										checked={selectedType === "normal" && true}
										onClick={() => setSelectedType("normal")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>Normal</p>
								</div>
							)}
							{product?.boneless && (
								<div className="s">
									<Radio
										checked={selectedType === "boneless" && true}
										onClick={() => setSelectedType("boneless")}
										sx={{
											color: "black",
											paddingRight: "10px",
											"&.Mui-checked": {
												color: "black",
											},
										}}
									/>
									<p>Boneless</p>
								</div>
							)}
						</div>
					</div>)}

					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Link
							to={`/products/${product._id}`}
							style={{ textDecoration: "none", color: "black" }}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<EditIcon />
								<small>Edit</small>
							</Box>
						</Link>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "center",
								alignItems: "center",
							}}
							onClick={() => deleteFuction(product._id)}
						>
							<DeleteSweepIcon />
							<small>Delete</small>
						</Box>
					</Box>
				</div>
			</div>
		</Card>
	);
};
