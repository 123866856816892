import { useState } from 'react';
import swal from "sweetalert";

const useCart = () => {
    const [cartItem, setCartItem] = useState([]);
    const [ProductsDatas, setProductsDatas] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalsell, setTotalsell] = useState();
    const [open, setOpen] = useState(false);
    const [tooping, setTooping] = useState([]);



    const addToCart = (cart) => {
        const index = cartItem.findIndex(c => c._id === cart._id)
        if (index < 0) {
            setCartItem(pre => [...pre, cart]);
            swal({
                text: "Added To Cart!",
                icon: "success",
                buttons: false,
                timer: 1000,
            });

        } else {
            increaseItem(index)
        }
    }

    const removeCart = (indexId) => {
        const newCartItem = cartItem.filter((item, index) => index !== indexId);
        setCartItem(newCartItem);
        swal({
            text: "Removed From Cart!",
            icon: "warning",
            buttons: false,
            timer: 1000,
        });
    }

    const increaseItem = (indexId) => {
        const newCartItem = cartItem.map((item, index) => index === indexId ? { ...item, quantity: item.quantity + 1, totalQuantityPrice: item.price * (item.quantity + 1) } : item);
        setCartItem(newCartItem);
        // console.log(newCartItem);
    }

    const decreaseItem = (indexId) => {
        const count = cartItem.filter((item, index) => index === indexId)[0].quantity;
        if (count === 1) {
            return false;
        } else {
            const newCartItem = cartItem.map((item, index) => index === indexId ? { ...item, quantity: item.quantity - 1, totalQuantityPrice: item.price * (item.quantity - 1) } : item)
            setCartItem(newCartItem);
        }

    }


    return {
        cartItem,
        setCartItem,
        ProductsDatas,
        setProductsDatas,
        addToCart,
        removeCart,
        increaseItem,
        decreaseItem,
        totalPrice,
        setTotalPrice,
        totalsell,
        setTotalsell,
        setOpen,
        open,
        setTooping,
        tooping,
        // setStatus,
        // status

    }
};

export default useCart;