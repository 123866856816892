const firebaseConfig = {
  apiKey: "AIzaSyCaXCrwNQZfzihae28rn9PPlgzKwlKIZgo",
  authDomain: "restaurants-app-7519f.firebaseapp.com",
  projectId: "restaurants-app-7519f",
  storageBucket: "restaurants-app-7519f.appspot.com",
  messagingSenderId: "301545225438",
  appId: "1:301545225438:web:fbf87fb5283ae29ac4d64f",
};

export default firebaseConfig;
