import { Box, Paper } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import useAuth from "../../Context/useAuth";
import "./AddAddress.css";

const AddAddress = () => {
	let addressId = useParams();
	const { register, handleSubmit, reset } = useForm();
	const navigate = useNavigate();
	const [address, setAddress] = useState(null);
	const { user } = useAuth();

	const onSubmit = (data) => {
		if (!addressId.id) {
			axios
				.post(
					"https://admin.sliceguys.co.uk/address-collection",
					data
				)
				.then((res) => {
					if (res.data.insertedId) {
						swal({
							text: "Address Added Successful!",
							icon: "success",
							buttons: false,
							timer: 2000,
						});
						reset();
						navigate("/payment");
					}
				}).catch((e) => {
					console.log(e);
				});
		} else {
			let updatedData = {
				Email: data.Email,
				address: data.address ? data.address : address.address,
				city: data.city ? data.city : address.city,
				firstname: data.firstname ? data.firstname : address.firstname,
				phone: data.phone ? data.phone : address.phone,
				postcode: data.postcode ? data.postcode : address.postcode,
			}
			axios
				.put(
					"https://admin.sliceguys.co.uk/address-collection/" + addressId.id,
					updatedData
				)
				.then((res) => {
					if (res.data) {
						swal({
							text: "Address Updated Successful!",
							icon: "success",
							buttons: false,
							timer: 2000,
						});
						reset();
						navigate("/payment");
					}

				}).catch((e) => {
					console.log(e);
				});
		}

	};
	useEffect(() => {
		if (addressId.id) {
			axios
				.get(
					"https://admin.sliceguys.co.uk/address-collection/" + addressId.id
				)
				.then((res) => {
					setAddress(res.data);
				});
		}
	}, [addressId.id]);
	const onReset = () => {
		reset();
	}

	return (
		<Box className="addressForm">
			<Paper className="addressFormContainer">
				<form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
					<Box className="rowContainer">
						<Box className="rowItem">
							<label> Full Name</label> <br />
							<input defaultValue={address?.firstname} type="text" {...register("firstname")} required />
						</Box>
						<Box className="rowItem">
							<label>Email</label> <br />
							<input
								type="email"
								value={user.email}
								{...register("Email")}
								required
							/>
						</Box>
					</Box>
					<Box className="rowContainer">
						<Box className="rowItem">
							<label>Phone Number</label> <br />
							<input type="number" defaultValue={address?.phone} {...register("phone")} required />
						</Box>
						<Box className="rowItem">
							<label>Address Line</label> <br />
							<input type="text" defaultValue={address?.address} {...register("address")} required />
						</Box>
					</Box>
					<Box className="rowContainer">
						<Box className="rowItem">
							<label>Town/City</label> <br />
							<input type="text" defaultValue={address?.city} {...register("city")} required />
						</Box>
						<Box className="rowItem">
							<label>Post Code</label> <br />
							<input type="text" defaultValue={address?.postcode} {...register("postcode")} required />
						</Box>
					</Box>

					<input type="submit" value="Done" className="frombutton" />
					<input type="reset" value="Reset" className="frombutton" />
				</form>
			</Paper>
		</Box>
	);
};

export default AddAddress;
