import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAuth from "../../Context/useAuth";
import Category from "../Category/Category";
import Loadding from "../loading/Loadding";
import Products from "../Products/Products";

function Menu() {
	const { ProductsDatas, setProductsDatas, user } = useAuth();
	const [alldata, setAlldata] = useState([]);
	const [favdata, setFavData] = useState([]);
	const [isActiveCat, setIsActiveCat] = useState("Deals");
	const [removeState, setRemoveState] = useState(false);

	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/allcategori")
			.then((res) => res.json())
			.then((data) => {
				data.sort((a, b) => a.order - b.order)
				setAlldata(data)
			});
	}, []);

	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/allproduts")
			.then((res) => res.json())
			.then((data) => {
				setProductsDatas(data)
			});
	}, [setProductsDatas]);

	useEffect(() => {
		if (user?.email) {
			const url = `https://admin.sliceguys.co.uk/favouritelist/${user.email}`;
			fetch(url)
				.then((res) => res.json())
				.then((data) => {
					setFavData(data)
				});
		}
	}, [user?.email, removeState]);



	const ProductsD =
		isActiveCat === "All"
			? ProductsDatas
			: ProductsDatas.filter((product) => product.category === isActiveCat);

	return (
		<div className="menu">
			<div className="container" style={{ padding: "20px 0px" }}>
				<Category
					setIsActiveCat={setIsActiveCat}
					isActiveCat={isActiveCat}
					alldata={alldata}
				/>
				{ProductsD.length > 0 ? (

					<Products ProductsD={ProductsD} allProducts={ProductsDatas} favdata={favdata} removeState={removeState} setRemoveState={setRemoveState} />
				) : (
					<Box
						style={{
							width: "100%",
							display: "flex",
							justifyContent: "center",
							paddingTop: "20px",
						}}
					>
						<Loadding></Loadding>
					</Box>
				)}
			</div>
		</div>
	);
}

export default Menu;
