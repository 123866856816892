import Button from '@mui/material/Button';
import * as React from 'react';
import { Link } from 'react-router-dom';


export default function CategorisMenu() {
    return (
        <div>
            <Link to="/categoriforms"
                style={{ textDecoration: 'none', color: 'black' }}
            >
                <Button
                    color="secondary"
                    sx={{ backgroundColor: '#222831' }}
                    variant="contained"
                    id="composition-button"

                >
                    Add Categories
                </Button>
            </Link>

        </div >

    );
}
