import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import useAuth from "../../Context/useAuth";
import DealIngredients from "./Deal-ingredients";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

const Ingreadients = ({ updatedProduct, products }) => {
	const { addToCart } = useAuth();
	const [open, setOpen] = useState(false);
	const [tooping, setTooping] = useState([]);
	const [allTopping, setAllTopping] = useState([]);
	const [toggle, setToggle] = useState(false);
	const [toggle2, setToggle2] = useState(false);
	const [totalIngredientPrice, setTotalIngredientPrice] = useState(0);
	const [isFussyEatersToppingsDone, setIsFussyEatersToppingsDone] = useState(false);
	const [isPizzaDone, setIsPizzaDone] = useState(false);
	const [isSidesDone, setIsSidesDone] = useState(false);
	const [isSidesToShareDone, setIsSidesToShareDone] = useState(false);
	const [isDriksDone, setIsDrinksDone] = useState(false);
	const [isDessertDone, setIsDessertDone] = useState(false);
	const [isShakesDone, setIsShakesDone] = useState(false);


	const DipsAvailableCategories = ["Pizza", "Wings", "Vegan", "Sides"];

	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/ingredients")
			.then((res) => res.json())
			.then((data) => setAllTopping(data));
	}, []);

	const addToopings = (id, product = false) => {
		if (updatedProduct.category === "Deals" && product) {
			setTooping([...tooping, product]);
		} else if (updatedProduct.category === "Deals" && !product) {
			const data = products.filter((value) => value._id === id);
			setTooping([...tooping, data[0]]);
		} else {
			const data = allTopping.filter((value) => value._id === id);
			setTooping([...tooping, data[0]]);
		}
	};

	const price = (p) => {
		const price = parseFloat(p);
		if (price === 0.00 || price === 0) {
			return 'Free';
		} else if (price < 1) {
			return `${price * 100}p`;
		} else {
			return `£${price}`
		}
	}

	useEffect(() => {
		const allData = allTopping.filter((value) => !tooping.includes(value));
		setAllTopping(allData);
	}, [tooping]);

	// <-- Deals Product Logic --> //
	useEffect(() => {
		if (updatedProduct.category === "Deals") {
			const pizzaCount = tooping.filter(t => t.category === "Pizza").length;
			// Double pack + Family Pack + Slice Guys + Any 2 Large Pizzas
			if (pizzaCount === 2 && (updatedProduct._id === "630b3907121840218c25632d" || updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct._id === "630b422f121840218c256330" || updatedProduct._id === "635f649f48c5bcafe186c09e")) {
				setIsPizzaDone(true);
			}
			//Tripple pack
			else if (pizzaCount === 3 && updatedProduct._id === "630b3c10121840218c25632e") {
				setIsPizzaDone(true);
			}
			//Single pack
			else if (pizzaCount === 1 && updatedProduct._id === "630b379b121840218c25632c") {
				setIsPizzaDone(true);
			}
			else {
				setIsPizzaDone(false);
			}

			const sidesCount = tooping.filter(t => t.category === "Sides").length;
			if (sidesCount > 0) {
				setIsSidesDone(true)
			} else {
				setIsSidesDone(false)
			}

			const sidesToShareCount = tooping.filter(t => t.category === "Sides To Share").length;
			if (sidesToShareCount > 0) {
				setIsSidesToShareDone(true)
			} else {
				setIsSidesToShareDone(false)
			}

			const drinksCount = tooping.filter(t => t.category === "Drinks").length;
			if (drinksCount > 0) {
				setIsDrinksDone(true)
			} else {
				setIsDrinksDone(false)
			}

			const dessertCount = tooping.filter(t => t.category === "Desserts").length;
			if (dessertCount > 0) {
				setIsDessertDone(true)
			} else {
				setIsDessertDone(false)
			}


		}
		const shakesCount = tooping.filter(t => t.category === "Shakes").length;
		if (shakesCount > 0) {
			setIsShakesDone(true)
		} else {
			setIsShakesDone(false)
		}
	}, [tooping, updatedProduct.category, updatedProduct._id, updatedProduct.title, isPizzaDone])

	const removeToopings = (indexId) => {
		const removedData = tooping.filter((value, index) => index === indexId)[0];
		const remainData = tooping.filter((value, index) => index !== indexId);
		setAllTopping([...allTopping, removedData])
		setTooping(remainData);
	};



	// <-- Handling Topping Prices --> //
	useEffect(() => {
		if (updatedProduct.category === "Shakes") {
			if (tooping.length === 0) {
				setTotalIngredientPrice(0)
			} else {
				setTotalIngredientPrice(tooping[0].toopingPrice)
			}
		} else if (updatedProduct.title === "Fussy Eaters") {
			if (tooping.filter(t => t.category === "Pizza").length <= 4) {
				let dipsValue = tooping.filter(t => t.category === "Dips").reduce(
					(pri, pro) => pri + parseFloat(pro.toopingPrice),
					0
				)
				setTotalIngredientPrice(dipsValue);
			} else {
				let pizzaToppings = tooping.filter(t => t.category === "Pizza");
				let toppingValue = pizzaToppings.slice(4, pizzaToppings.length).reduce(
					(pri, pro) => pri + parseFloat(pro.toopingPrice),
					0
				)
				let dipsValue = tooping.filter(t => t.category === "Dips").reduce(
					(pri, pro) => pri + parseFloat(pro.toopingPrice),
					0
				)
				setTotalIngredientPrice(toppingValue + dipsValue);
			}
		}
		// else if (updatedProduct.category === "Deals") {
		// 	setTotalIngredientPrice(0);
		// }
		else {
			let value = tooping.reduce(
				(pri, pro) => pri + (pro.toopingPrice ? parseFloat(pro.toopingPrice) : 0),
				0
			)
			setTotalIngredientPrice(value);
		}
	}, [updatedProduct.category, updatedProduct.title, tooping])

	useEffect(() => {
		if (updatedProduct.title === "Fussy Eaters") {
			if (tooping.filter(t => t.category === "Pizza").length >= 4) {
				setIsFussyEatersToppingsDone(true);
			} else {
				setIsFussyEatersToppingsDone(false);
			}

		} else {
			setIsFussyEatersToppingsDone(true);
		}
	}, [updatedProduct.title, tooping])

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const handleCart = () => {
		const totalToopingPrice = totalIngredientPrice;

		if (updatedProduct.category === "Deals") {
			if (updatedProduct._id === "630b379b121840218c25632c" && tooping.length < 2) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}
			// Double Pack
			if (updatedProduct._id === "630b3907121840218c25632d" && tooping.length < 2) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}
			// Triple Pack
			if (updatedProduct._id === "630b3c10121840218c25632e" && tooping.length < 3) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}
			// Family Pack
			if (updatedProduct._id === "630b3d0f121840218c25632f" && tooping.length < 5) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}
			if (updatedProduct.title === "For 2 Slice Guys" && tooping.length < 5) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}
			if (updatedProduct.title === "Any 2 Large Pizzas" && tooping.length < 2) {
				swal({
					text: "Please Select All Products!",
					icon: "warning",
					showConfirmButton: true,
					timer: 2000,
				});
				return false;
			}

		}

		let allToopingNames = "";
		tooping.forEach(t => {
			if (t.ToopingName) {
				allToopingNames = allToopingNames + t.ToopingName + ", "
			} else {
				allToopingNames = allToopingNames + t.title + ", "
			}
		})

		const data = {
			...updatedProduct,
			toopingNames: allToopingNames,
			totalToopingPrice: totalToopingPrice,
			price: parseFloat(updatedProduct.price) + parseFloat(totalToopingPrice),
			totalQuantityPrice: parseFloat(updatedProduct.price) + parseFloat(totalToopingPrice),
		};
		addToCart(data);
		setTooping([]);
		setOpen(false);
	};

	return (
		<div>
			<div className="addButton">
				<Button
					className="btn-add"
					variant="contained"
					onClick={handleClickOpen}
				>
					Add to Cart
				</Button>
			</div>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					{updatedProduct.title}
					<br />
					<span style={{ fontWeight: "400", fontSize: ".8rem" }}>{updatedProduct.description}</span>
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<TableContainer
						sx={{
							backgroundColor: "#E5E5E5",
							color: "white",
							maxWidth: "550px",
							width: "100%"
						}}
					>
						<Table aria-label="spanning table">
							{tooping.length > 0 && (<TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										{(updatedProduct.category === "Pizza" || updatedProduct.category === "Nachos") ? "Added Toppings"
											: updatedProduct.category === "Shakes" ? "Added Flavours"
												: updatedProduct.category === "Deals" ? "Selected Products" : "Dips"}
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle(!toggle)}
											style={{
												transform: toggle
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>)}
							{tooping && (<TableBody style={{ display: toggle && "none" }}>
								{tooping ? (tooping.map((top, index) => (
									<TableRow key={index}>
										{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
											<b>{top.title}</b><br />({top.description}) {top.category === "Pizza" ? `+ (${top.ToopingName.split("(")[1].split(")")})` : ""}
										</TableCell>}
										{updatedProduct.category !== "Deals" && <TableCell sx={{ py: ".5rem" }}>
											{top.ToopingName}
										</TableCell>
										}
										{updatedProduct.category === "Shakes" && <TableCell sx={{ py: 0 }}>
											{(tooping.length >= 1 && index !== 0) ? price(0) : price(top.toopingPrice)}
										</TableCell>}
										{updatedProduct.category === "Pizza" && <TableCell sx={{ py: 0 }}>
											{(updatedProduct.title === "Fussy Eaters" && index <= 3 && top.category === "Pizza") ? price(0) : price(top.toopingPrice)}
										</TableCell>}
										{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
											<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
										</TableCell>}
										{(updatedProduct.category !== "Deals" && updatedProduct.category !== "Pizza" && updatedProduct.category !== "Shakes") && <TableCell sx={{ py: ".5rem" }}>
											{price(top.toopingPrice)}
										</TableCell>}
										<TableCell align="center" sx={{ py: 0 }}>
											<h3
												className="addToopping"
												onClick={() => removeToopings(index)}
											>
												-
											</h3>
										</TableCell>
									</TableRow>
								))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>)}

							{/* Toppings For Pizza & Nachos */}
							{(updatedProduct.category === "Pizza" || updatedProduct.category === "Nachos") && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Add {updatedProduct.category} Toppings
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}

							{(updatedProduct.category === "Pizza" || updatedProduct.category === "Nachos") && <TableBody style={{ display: toggle2 && "none" }}>
								{allTopping ? (
									allTopping.filter(t => t.category === "Pizza").map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: 0 }}>
												{top.ToopingName}
											</TableCell>
											<TableCell sx={{ py: 0 }}>
												{isFussyEatersToppingsDone ? price(top.toopingPrice) : price(0)}
											</TableCell>
											<TableCell align="center" sx={{ py: 0 }}>
												<h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3>
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}

							{/* Mixin For Shakes */}
							{(updatedProduct.category === "Shakes" && !isShakesDone) && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Mix It Up
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}

							{(updatedProduct.category === "Shakes" && !isShakesDone) && <TableBody style={{ display: toggle2 && "none" }}>
								{allTopping ? (
									allTopping.filter(t => t.category === "Shakes" && !isShakesDone).map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: 0 }}>
												{top.ToopingName}
											</TableCell>
											<TableCell sx={{ py: 0 }}>
												{tooping.length >= 1 ? price(0) : price(top.toopingPrice)}
											</TableCell>
											<TableCell align="center" sx={{ py: 0 }}>
												<h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3>
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}

							{/* For Dips */}
							{DipsAvailableCategories.includes(updatedProduct.category) && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Add Dips
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}

							{DipsAvailableCategories.includes(updatedProduct.category) && <TableBody style={{ display: toggle2 && "none" }}>
								{allTopping ? (
									allTopping.filter(t => t.category === "Dips").map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: 0 }}>
												{top.ToopingName}
											</TableCell>
											<TableCell sx={{ py: 0 }}>
												{price(top.toopingPrice)}
											</TableCell>
											<TableCell align="center" sx={{ py: 0 }}>
												<h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3>
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}

							{/* For Deals */}
							{/* Pizza Deals */}
							{(updatedProduct.category === "Deals" && !isPizzaDone) && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Select Pizza
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}
							{(updatedProduct.category === "Deals" && !isPizzaDone) && <TableBody style={{ display: toggle2 && "none" }}>
								{products ? (
									products.filter(t => t.category === "Pizza").map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: ".5rem" }}>
												<b>{top.title}</b>
												<br />
												({top.description})
											</TableCell>
											{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
												<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
											</TableCell>}
											<TableCell align="center" sx={{ py: 0 }}>
												{/* <h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3> */}
												<DealIngredients updatedProduct={top} products={products} toppingId={top._id} addPizza={addToopings} />
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}

							{/* Classic Sides Deals */}
							{((updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isSidesDone) && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Select Classic Side
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}
							{((updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isSidesDone) && <TableBody style={{ display: toggle2 && "none" }}>
								{products ? (
									products.filter(t => t.category === "Sides" && (updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys")).map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: ".5rem" }}>
												<b>{top.title}</b>
												<br />
												({top.description})
											</TableCell>
											{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
												<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
											</TableCell>}
											<TableCell align="center" sx={{ py: 0 }}>
												<h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3>
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}
							{/* Sides To Share Deals */}
							{((updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isSidesToShareDone) && <TableHead>
								<TableRow>
									<TableCell sx={{ color: "#F2D7D5" }}>
										Select Sides To Share
									</TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
									<TableCell sx={{ color: "#F2D7D5" }}>
										<KeyboardArrowDownIcon
											onClick={() => setToggle2(!toggle2)}
											style={{
												transform: toggle2
													? "rotate(360deg)"
													: "rotate(180deg)",
											}}
										/>
									</TableCell>
								</TableRow>
							</TableHead>}
							{((updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isSidesToShareDone) && <TableBody style={{ display: toggle2 && "none" }}>
								{products ? (
									products.filter(t => t.category === "Sides To Share").map((top, index) => (
										<TableRow key={index}>
											<TableCell sx={{ py: ".5rem" }}>
												<b>{top.title}</b>
												<br />
												({top.description})
											</TableCell>
											{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
												<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
											</TableCell>}
											<TableCell align="center" sx={{ py: 0 }}>
												<h3
													className="addToopping"
													onClick={() => addToopings(top._id)}
												>
													+
												</h3>
											</TableCell>
										</TableRow>
									))
								) : (
									<h2>loadding</h2>
								)}
							</TableBody>}
							{/* Drinks Deals */}
							{((updatedProduct._id === "630b379b121840218c25632c" || updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isDriksDone) &&
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: "#F2D7D5" }}>
											Select 1 Drink
										</TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}>
											<KeyboardArrowDownIcon
												onClick={() => setToggle2(!toggle2)}
												style={{
													transform: toggle2
														? "rotate(360deg)"
														: "rotate(180deg)",
												}}
											/>
										</TableCell>
									</TableRow>
								</TableHead>}
							{((updatedProduct._id === "630b379b121840218c25632c" || updatedProduct._id === "630b3d0f121840218c25632f" || updatedProduct.title === "For 2 Slice Guys") && !isDriksDone) &&
								<TableBody style={{ display: toggle2 && "none" }}>
									{products ? (
										products.filter(t => t.category === "Drinks" && ((updatedProduct._id === "630b379b121840218c25632c" && t.description.toLowerCase().includes('can')) || (updatedProduct._id !== "630b379b121840218c25632c" && t.description.toLowerCase().includes('bottle') && !t.description.toLowerCase().includes('water')))).map((top, index) => (
											<TableRow key={index}>
												<TableCell sx={{ py: ".5rem" }}>
													<b>{top.title}</b>
													<br />
													({top.description})
												</TableCell>
												{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
													<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
												</TableCell>}
												<TableCell align="center" sx={{ py: 0 }}>
													<h3
														className="addToopping"
														onClick={() => addToopings(top._id)}
													>
														+
													</h3>
												</TableCell>
											</TableRow>
										))
									) : (
										<h2>loadding</h2>
									)}
								</TableBody>}

							{/* Desserts Deals */}
							{((updatedProduct._id === "630b3d0f121840218c25632f") && !isDessertDone) &&
								<TableHead>
									<TableRow>
										<TableCell sx={{ color: "#F2D7D5" }}>
											Select 1 Dessert
										</TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}></TableCell>
										<TableCell sx={{ color: "#F2D7D5" }}>
											<KeyboardArrowDownIcon
												onClick={() => setToggle2(!toggle2)}
												style={{
													transform: toggle2
														? "rotate(360deg)"
														: "rotate(180deg)",
												}}
											/>
										</TableCell>
									</TableRow>
								</TableHead>}
							{((updatedProduct._id === "630b3d0f121840218c25632f") && !isDessertDone) &&
								<TableBody style={{ display: toggle2 && "none" }}>
									{products ? (
										products.filter(t => t.category === "Desserts").map((top, index) => (
											<TableRow key={index}>
												<TableCell sx={{ py: ".5rem" }}>
													<b>{top.title}</b>
													<br />
													({top.description})
												</TableCell>
												{updatedProduct.category === "Deals" && <TableCell sx={{ py: ".5rem" }}>
													<img src={`data:image/*;base64, ${top.file}`} alt="img" style={{ width: "60px", maxHeight: "60px" }} />
												</TableCell>}
												<TableCell align="center" sx={{ py: 0 }}>
													<h3
														className="addToopping"
														onClick={() => addToopings(top._id)}
													>
														+
													</h3>
												</TableCell>
											</TableRow>
										))
									) : (
										<h2>loadding</h2>
									)}
								</TableBody>}

						</Table>
					</TableContainer>
				</DialogContent>
				<div className="addButton">
					<Button
						className="btn-add"
						variant="contained"
						onClick={handleCart}
					>
						Add to Cart
						<span style={{ margin: "0px 20px" }}>
							£ {(parseFloat(updatedProduct.price) + parseFloat(totalIngredientPrice)).toFixed(2)}
						</span>
					</Button>
				</div>
			</BootstrapDialog>
		</div>
	);
};
export default Ingreadients;
