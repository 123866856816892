import { Grid } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";
import OrderComp from "../../components/Profile/Order";
import ProfileComp from "../../components/Profile/Profile";
import ProfileNav from "../../components/Profile/ProfileNav";

import "./Profile.css";

function Profile() {
  const { type } = useParams();
  const [currentNav, setCurrentNav] = useState(type);

  return (
    <div className="mainsection">
      <h3 className="favTitle">Profile</h3>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4} lg={3}>
          <ProfileNav currentNav={currentNav} setCurrentNav={setCurrentNav} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={9}>
          {currentNav === "my-profile" && <ProfileComp />}
          {currentNav === "my-orders" && <OrderComp />}
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
