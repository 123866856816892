import PeopleIcon from "@mui/icons-material/PeopleOutlined";
import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const TodaysOrders = ({ alldata }) => {
	const alldatas = alldata.filter((data) => data.status === "Pending Order");

	return (
		<Link to="/todaysorders" style={{ textDecoration: "none" }}>
			<Card>
				<CardContent>
					<Grid
						container
						spacing={3}
						sx={{ justifyContent: "space-between" }}
					>
						<Grid item>
							<Typography
								color="textSecondary"
								gutterBottom
								variant="overline"
							>
								all Pending
							</Typography>
							<Typography color="textPrimary" variant="h4">
								{alldatas ? alldatas.length : 0}
							</Typography>
						</Grid>
						<Grid item>
							<Avatar
								sx={{
									backgroundColor: "success.main",
									height: 56,
									width: 56,
								}}
							>
								<PeopleIcon />
							</Avatar>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Link>
	);
};

export default TodaysOrders;
