import axios from "axios";
import { } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import io from 'socket.io-client';
import swal from "sweetalert";
// import initializaAuthentication from "../screens/Registration/firebase/firebase.init";
// initializaAuthentication();

const useData = () => {
	const [user, setUser] = useState({});
	const [loading, setLoading] = useState(true);
	const [loading2, setLoading2] = useState(true);
	const [allerrors, setAllErrors] = useState();
	const [adminData, setAdminData] = useState(false);
	const navigate = useNavigate();
	const [noti, setNoti] = useState()
	const socket = io.connect("https://admin.sliceguys.co.uk");
	const [updatedOrder, setUpdatedOrder] = useState(null);
	// const auth = getAuth();

	useEffect(() => {
		socket.on("order_updated", (order_data) => {
			const orderId = order_data.data.order_id;
			if (noti.find(n => n._id === orderId)) {
				setUpdatedOrder(orderId);
			}
		})
	}, [socket, noti]);

	const registerUser = (userData) => {
		// setLoading(true);
		// createUserWithEmailAndPassword(auth, userData.email, userData.password)
		// 	.then((userCredential) => {
		// 		const newUser = {
		// 			email: userData.email,
		// 			displayName: userData.username,
		// 		};
		// 		setUser(newUser);

		// 		saveUsers(userData, "POST");
		// 		setAllErrors("");

		// 		updateProfile(auth.currentUser, {
		// 			displayName: userData.username,
		// 		})
		// 			.then(() => { })
		// 			.catch((error) => {
		// 				setAllErrors(error.message);
		// 			});
		// 	})
		// 	.catch((error) => {
		// 		setAllErrors(error.message);
		// 	})
		// 	.finally(() => setLoading(false));

		setLoading(true);
		axios
			.post('https://admin.sliceguys.co.uk/register/', userData)
			.then((res) => {
				if (res.data.result) {
					const newUser = {
						email: res.data.user.email,
						displayName: res.data.user.username,
						role: res.data.user.role
					};
					setUser(newUser);
					window.localStorage.setItem('user', JSON.stringify(newUser));
					setAllErrors("");
					setLoading(false);
					navigate("/")
				} else {
					setAllErrors(res.data.message);
					setLoading(false);
				}
			}).catch((e) => {
				console.log(e);
				setLoading(false)
			});
	};

	const userSignIn = (userData, navigate, location) => {
		// setLoading(true);
		// signInWithEmailAndPassword(auth, userData.email, userData.password)
		// 	.then((userCredential) => {
		// 		updateUsers(userData, "PUT");
		// 		const destination = location?.state?.from || "/";
		// 		navigate(destination);
		// 		setUser(userCredential.user);
		// 		setAllErrors("");
		// 	})
		// 	.catch((error) => {
		// 		setAllErrors(error.message);
		// 	})
		// 	.finally(() => setLoading(false));
		setLoading(true);
		axios
			.post('https://admin.sliceguys.co.uk/login/', userData)
			.then((res) => {
				if (res.data.result) {
					const newUser = {
						email: res.data.user.email,
						displayName: res.data.user.username,
						role: res.data.user.role
					};
					setUser(newUser);
					window.localStorage.setItem('user', JSON.stringify(newUser));
					setAllErrors("");
					setLoading(false);
					const destination = location?.state?.from || "/";
					navigate(destination);
				} else {
					setAllErrors(res.data.message);
					setLoading(false);
				}
			}).catch((e) => {
				console.log(e);
				setLoading(false)
			});
	};

	const handleForgetPassword = (email) => {
		setLoading(true);
		const userData = {
			email
		}
		axios
			.post('https://admin.sliceguys.co.uk/forgot-password/', userData)
			.then((res) => {
				if (res.data.result) {
					swal({
						text: "An Email With OTP Sent To Your Email.",
						icon: "success",
						buttons: false,
						timer: 2000,
					});
				} else {
					setAllErrors(res.data.message);
					setLoading(false);
				}
			}).catch((e) => {
				console.log(e);
				setLoading(false)
			});
		setLoading(false);
	};

	const userSignOut = () => {
		setLoading(true);
		setLoading2(true);
		// signOut(auth)
		setUser({})
		window.localStorage.removeItem('user')
		setAllErrors("");
		setLoading(false)
		setLoading2(false);
	};

	//save and update user details

	// const saveUsers = (data, methods) => {
	// 	// console.log(data, methods)
	// 	fetch("https://admin.sliceguys.co.uk/alluser", {
	// 		method: methods,
	// 		headers: {
	// 			"content-type": "application/json",
	// 		},
	// 		body: JSON.stringify(data),
	// 	})
	// 		.then((res) => res.json())
	// 		.then((data) => {
	// 			if (data.insertedId) {
	// 				navigate("/");
	// 			}
	// 		});
	// };
	// const updateUsers = (data, methods) => {
	// 	fetch(
	// 		`https://admin.sliceguys.co.uk/alluser/${data.email}`,
	// 		{
	// 			method: methods,
	// 			headers: {
	// 				"content-type": "application/json",
	// 			},
	// 			body: JSON.stringify(data),
	// 		}
	// 	)
	// 		.then((res) => res.json())
	// 		.then((data) => {
	// 			if (data.insertedId) {
	// 			}
	// 		});
	// };

	// Set User Admin Or Not
	// useEffect(() => {
	// 	if (user.email) {
	// 		const url = `https://admin.sliceguys.co.uk/useradmin/${user.email}`;

	// 		fetch(url)
	// 			.then((res) => res.json())
	// 			.then((data) => {
	// 				console.log(data)
	// 				setAdminData(data.admin);
	// 				setLoading2(false);
	// 			})
	// 			.catch(() => setLoading2(false));
	// 	}
	// }, [user.email]);

	// Set User Admin Or Not
	useEffect(() => {
		if (user?.role && user?.role === "admin") {
			setAdminData(true);
			setLoading(false);
			setLoading2(false);
		} else {
			setAdminData(false);
			setLoading(false);
			setLoading2(false);
		}
	}, [user]);

	useEffect(() => {
		if (user?.email && user?.role !== "admin") {
			const url = `https://admin.sliceguys.co.uk/my-product/${user.email}`;
			fetch(url)
				.then((res) => res.json())
				.then((data) => {
					setNoti(data)
				});
		}
	}, [user, updatedOrder, setNoti]);

	useEffect(() => {
		// if (window.localStorage.getItem('user')) {
		setUser(JSON.parse(window.localStorage.getItem('user')))
		// } else {
		// 	setUser({});
		// }
	}, []);

	// useEffect(() => {
	// 	console.log(window.localStorage.getItem('user'))
	// 	if (window.localStorage.getItem('user') && user.role) {
	// 		window.localStorage.setItem('user', JSON.stringify(user));
	// 	}

	// }, [user])

	//observe user present
	// useEffect(() => {
	// 	const unsubscribe = onAuthStateChanged(auth, (user) => {
	// 		if (user) {
	// 			setUser(user);
	// 		} else {
	// 			setUser({});
	// 		}
	// 		setLoading(false);
	// 	});
	// 	return () => unsubscribe;
	// }, [auth]);

	return {
		user,
		loading,
		loading2,
		allerrors,
		registerUser,
		userSignIn,
		handleForgetPassword,
		userSignOut,
		adminData,
		noti,
		setNoti,
	};
};

export default useData;
