import FolderIcon from "@mui/icons-material/Folder";
import { Avatar, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { DashboardLayout } from "../layout/dashboard-layout";
import "./form.css";

const ProductForms = () => {
	const [alldata, setAlldata] = useState([]);
	const [cat, setCat] = useState([]);
	const navigate = useNavigate();
	useEffect(() => {
		fetch("https://admin.sliceguys.co.uk/allcategori")
			.then((res) => res.json())
			.then((data) => {
				setAlldata(data.filter(d => d.categoriName !== "All"))
			});
	}, []);
	useEffect(() => {
		if (alldata.length > 0) {
			setCat(alldata[0].categoriName)
		}
	}, [alldata]);

	const handleCat = (e) => {
		setCat(e.target.value)
	}

	const { register, handleSubmit, reset } = useForm();
	const [myfiles, setMyFiles] = useState(null);
	const inputRef = useRef(HTMLInputElement);

	const getImagePreview = (e) => {
		let image = URL.createObjectURL(e.target.files[0]);
		let imagediv = document.getElementById("preview");
		imagediv.src = image;
		setMyFiles(e.target.files[0]);
	};

	const onSubmit = (data) => {
		if (myfiles?.size > 2000000) {
			swal({
				text: "Your image is over 2MB",
				icon: "warning",
			});
		} else {
			const formData = new FormData();
			formData.append("title", data.title);
			formData.append("category", cat);
			formData.append("description", data.description);
			// formData.append("regularprice", data.regularPrice);
			formData.append("regularDiscount", data.regularDiscount);
			// formData.append("mediumPrice", data.mediumPrice);
			formData.append("mediumDiscount", data.mediumDiscount ? data.mediumDiscount : '');
			// formData.append("largePrice", data.largePrice);
			formData.append("largeDiscount", data.largeDiscount ? data.largeDiscount : '');
			formData.append("extraLargeDiscount", data.extraLargeDiscount ? data.extraLargeDiscount : '');
			if (cat === "Pizza") {
				formData.append("deep_pan", data.deep_pan ? data.deep_pan : null);
				formData.append("thin_crust", data.thin_crust ? data.thin_crust : null);
				formData.append("stuffed_crust", data.stuffed_crust ? data.stuffed_crust : null);
			}
			if (cat === "Wings") {
				formData.append("normal", data.normal ? data.normal : null);
				formData.append("boneless", data.boneless ? data.boneless : null);
			}
			formData.append("myfiles", myfiles);

			fetch("https://admin.sliceguys.co.uk/allproduts", {
				method: "POST",
				body: formData,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.insertedId) {
						swal({
							text: "Successfully upload your product",
							icon: "success",
							buttons: false,
							timer: 2000,
						});
						navigate("/products");
					}
				})
				.catch((error) => {
					console.error("Error:", error);
				});
		}
	};

	return (
		<Box className="addressForm">
			<Paper className="addressFormContainer">
				<form onSubmit={handleSubmit(onSubmit)} onReset={reset}>
					<Box className="rowContainer">
						<Box className="rowItem">
							<img
								src=""
								alt=""
								id="preview"
								style={{
									height: "100px",
									width: "100px",
									borderRadius: "100%",
									objectFit: "cover",
								}}
							/>
						</Box>
						<Box className="rowItem">
							<input
								type="file"
								accept="image/*"
								// {...register("imageFile")}
								onChange={getImagePreview}
								style={{ display: "none" }}
								ref={inputRef}
							/>
							<Avatar onClick={() => inputRef.current.click()}>
								<FolderIcon />
							</Avatar>
						</Box>
						<Box className="rowItem">
							<label>Product Category</label> <br />
							<select
								{...register("category")}
								style={{ height: "40px", width: "160px" }}
								onChange={handleCat}
							>
								{alldata ? (
									alldata.map((data) => (
										<option key={data._id} value={data.categoriName}>
											{data.categoriName}
										</option>
									))
								) : (
									<option value="burger">Berger</option>
								)}
							</select>
						</Box>
					</Box>
					<Box className="rowContainer">
						<Box className="rowItem" style={{ width: "100%" }}>
							<label>Product title</label> <br />
							<input style={{ width: "100%" }}
								type="text"
								placeholder="Product Name"
								{...register("title")}
							/>
						</Box>
					</Box>
					<Box className="rowContainer">
						<Box className="rowItem" style={{ width: "100%" }}>
							<label>Product Description</label>
							<input style={{ width: "100%" }}
								type="text"
								placeholder="Description"
								{...register("description")}
							/>
						</Box>
					</Box>
					<br /><label><b>Price(s) :</b></label> <br />
					{(cat !== "Pizza" && cat !== "Wings" && cat !== "Deals") &&
						<Box className="rowContainer">
							<Box className="rowItem">
								<label>Regular size price</label><br />
								<input type="number" step="0.01" {...register("regularDiscount")} />
							</Box>
						</Box>
					}
					{(cat === 'Wings') && <Box className="rowContainer">
						<Box className="rowItem">
							<label>6 Pcs Price</label>
							<input type="number" step="0.01" {...register("regularDiscount")} />
						</Box>
						<Box className="rowItem">
							<label>10 Pcs Price</label>
							<input type="number" step="0.01" {...register("mediumDiscount")} />
						</Box>
					</Box>}
					{(cat === 'Pizza' || cat === 'Deals') && <Box className="rowContainer">
						<Box className="rowItem">
							<label>Regular size price</label>
							<input type="number" step="0.01" {...register("regularDiscount")} />
						</Box>
						<Box className="rowItem">
							<label>Medium size price</label>
							<input type="number" step="0.01" {...register("mediumDiscount")} />
						</Box>

					</Box>}
					{(cat === 'Pizza' || cat === 'Deals') && <Box className="rowContainer">
						<Box className="rowItem">
							<label>Large size price</label>
							<input type="number" step="0.01" {...register("largeDiscount")} />
						</Box>
						<Box className="rowItem">
							<label>Extra Large size price / Discount</label> <br />
							<input type="number" step="0.01" {...register("extraLargeDiscount")} />
						</Box>
					</Box>}
					<br />
					{cat === 'Pizza' && (<label><b>Crust :</b></label>)}
					{cat === 'Wings' && (<label><b>Type :</b></label>)}
					<br />
					{cat === 'Pizza' && <Box className="rowContainer">
						<Box className="rowItem">
							<label>Deep Pan Addition</label> <br />
							<input type="number" step="0.01" defaultValue="0" {...register("deep_pan")} />
						</Box>
						<Box className="rowItem">
							<label>Thin Crust Addition</label> <br />
							<input type="number" step="0.01" defaultValue="0" {...register("thin_crust")} />
						</Box>
					</Box>}

					{cat === 'Pizza' && <Box className="rowContainer">
						<Box className="rowItem">
							<label>Stuffed Crust Addition</label> <br />
							<input type="number" step="0.01" defaultValue="2" {...register("stuffed_crust")} />
						</Box>
					</Box>}

					{cat === 'Wings' && <Box className="rowContainer">
						<Box className="rowItem">
							<label>Normal</label> <br />
							<input type="number" step="0.01" defaultValue="0" {...register("normal")} />
						</Box>
						<Box className="rowItem">
							<label>Boneless</label> <br />
							<input type="number" step="0.01" defaultValue="0" {...register("boneless")} />
						</Box>
					</Box>}


					<input type="submit" value="Submit" className="frombutton" />
					<input type="reset" value="Reset" className="frombutton" />
				</form>
			</Paper>
		</Box>
	);
};

ProductForms.getLayout = (page) => <DashboardLayout>{page}</DashboardLayout>;

export default ProductForms;
