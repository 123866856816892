import { useState } from 'react';

const useStatus = () => {
    const [status, setStatus] = useState(false);

    return {
        status,
        setStatus,
    }
}

export default useStatus;